/* eslint-disable no-nested-ternary */
import { Paper, Table, TableBody, TableContainer, TableHead } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMappingPresets } from 'redux/reducers/files/reducer';
import { getMappingPresets, getMappingPresetsIsLoad } from 'redux/reducers/files/selectors';
import { StyledTableCell, StyledTableRow } from 'ui/StyledTable';
import { Loader } from 'ui/Loader';
import { DeleteDialog } from 'ui/DeleteDialog';

export const MappingPresetsList: FC = () => {
	const mappingPresetsList = useSelector(getMappingPresets);
	const mappingPresetsIsLoad = useSelector(getMappingPresetsIsLoad);
	const dispatch = useDispatch();

	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false);
	};
	const [mappingItem, setMappingItem] = useState(0);

	const handleOpenDeleteDialog = () => {
		setOpenDeleteDialog(true);
	};
	const handleDeleteMapping = () => {
		handleCloseDeleteDialog();
		dispatch(
			deleteMappingPresets({
				clientId: mappingPresetsList?.items[mappingItem]?.clientId ?? 0,
				mappingId: mappingPresetsList?.items[mappingItem]?.id ?? 0,
			}),
		);
	};

	return (
		<>
			{!mappingPresetsList?.items.length && !mappingPresetsIsLoad && (
				<div className="empty-data">
					<p className="empty__text">No mapping found...</p>
				</div>
			)}

			{mappingPresetsIsLoad && <Loader />}

			{!!mappingPresetsList?.items.length && !mappingPresetsIsLoad && (
				<Paper sx={{ mt: '-24px', ml: '-24px', mr: '-24px' }}>
					<TableContainer sx={{ maxHeight: 800 }}>
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<StyledTableRow>
									<StyledTableCell align="left" colSpan={4}>
										Name
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={3}>
										Created at
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										Action
									</StyledTableCell>
								</StyledTableRow>
							</TableHead>
							<TableBody>
								{mappingPresetsList?.items?.map((item, index) => (
									<StyledTableRow hover key={item.id}>
										<StyledTableCell align="left" colSpan={4}>
											{item.name}
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={3}>
											{new Date(item.createdAt).toLocaleDateString()}
										</StyledTableCell>

										<StyledTableCell align="center" colSpan={1}>
											<DeleteIcon
												sx={{ color: '#016193', cursor: 'pointer' }}
												onClick={(e) => {
													e.stopPropagation();
													setMappingItem(index);
													handleOpenDeleteDialog();
												}}
											/>
										</StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			)}
			<DeleteDialog
				open={openDeleteDialog}
				setClose={handleCloseDeleteDialog}
				handleDelete={handleDeleteMapping}
				text="Are you sure you want to delete this mapping?"
			/>
		</>
	);
};
