import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import { TSettingsData, TSettingsStore } from './types';

// ==========================================:
const getSettingsState = (state: TStoreState): TSettingsStore => state.settings;
export const getSettings = createSelector(
	[getSettingsState],
	(settings: TSettingsStore) => settings,
);

// ====================================================:
export const getSettingsIsLoad = createSelector(
	[getSettings],
	(settings: TSettingsStore): boolean => settings.settingsLoader,
);

// ====================================================:
export const getSettingsList = createSelector(
	[getSettings],
	(settings: TSettingsStore): TSettingsData => settings.settings,
);

// ====================================================:
