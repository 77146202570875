/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { newVATReportRequest } from 'redux/reducers/vatreport/reducer';
import { newVatReport, vatReportStatus } from 'redux/reducers/vatreport/selectors';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { LinearProgressWithLabel } from '../LinearProgressWithLabel';

export const VATReportDownload: FC = () => {
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const newVatReportTaskId = useSelector(newVatReport);
	const downloadStatus = useSelector(vatReportStatus);

	const [startDownload, setStartDownload] = useState(false);
	const [date, setDate] = useState<string | null>(null);
	const [endDownload, setEndDownload] = useState(false);

	const downloadHandler = () => {
		setEndDownload(false);
		setDate(null);
		dispatch(newVATReportRequest());
		setStartDownload(true);
	};

	useEffect(() => {
		if (newVatReportTaskId?.taskId) {
			navigate(`/vat-report/${newVatReportTaskId.taskId}`);
		}
	}, [navigate, newVatReportTaskId?.taskId]);

	return (
		<div>
			<Button variant="contained" onClick={downloadHandler} disabled={startDownload}>
				Download IOSS VAT Reports
			</Button>

			{startDownload && (
				<Box sx={{ width: '100%', marginTop: '40px' }}>
					<LinearProgressWithLabel value={downloadStatus?.progress ?? 0} />
				</Box>
			)}
			{date && (
				<p style={{ color: '#016193', fontWeight: 600, marginTop: '40px' }}>
					Fiscal report date : {date.slice(0, 10)}
				</p>
			)}

			{endDownload && (
				<p style={{ color: '#016193', fontWeight: 600 }}>File downloaded successfully!</p>
			)}
		</div>
	);
};
