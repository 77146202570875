import { Client } from 'components/Client';
import { Page } from 'layouts/Page';
import { LoginPage } from 'pages/LoginPage';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getIsAuth } from 'redux/reducers/auth/selectors';

export const ClientPage: FC = () => {
	const ifAuth = useSelector(getIsAuth);

	return ifAuth ? (
		<Page title="Home">
			<Client />
		</Page>
	) : (
		<LoginPage />
	);

	// return (
	// 	<Page title="Client">
	// 		<Client />
	// 	</Page>
	// );
};
