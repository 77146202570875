/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-restricted-syntax */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	SelectChangeEvent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { filesExampleRequest, startUploading } from 'redux/reducers/files/reducer';
import {
	getFilesExampleList,
	getFilesExampleListIsLoad,
	getFilesFieldsList,
	getFilesSeparatorsList,
	getMappingPresets,
} from 'redux/reducers/files/selectors';
import { nanoid } from '@reduxjs/toolkit';
import { Loader } from 'ui/Loader';
import { notificationContainer } from 'utils/notificationContainer';
import { TField, TInput, TMappingFileDialogProps } from './types';
import { SaveMappingDialog } from './SaveMappingDialog';

export const MappingFileDialog: FC<TMappingFileDialogProps> = ({
	clientId,
	open,
	setClose,
	fileId,
}) => {
	const dispatch = useDispatch();
	const fieldsList = useSelector(getFilesFieldsList);
	const fieldsListArrayInit = fieldsList ? [{ id: '--', name: '--' }, ...fieldsList] : [];
	const [fieldsListArray, setFieldsListArray] = useState(fieldsListArrayInit);

	const exampleList = useSelector(getFilesExampleList);
	const exampleListIsLoad = useSelector(getFilesExampleListIsLoad);
	const mappingPresetsList = useSelector(getMappingPresets);

	const initInputArray = exampleList?.Data[0].map(() => {
		return {
			id: '--',
			name: '--',
			iIndex: 0,
		};
	}) ?? [
		{
			id: '--',
			name: '--',
			iIndex: 0,
		},
	];

	const separatorsList = useSelector(getFilesSeparatorsList);
	const [separatorType, setSeparatorType] = useState(0);
	const [headerRow, setHeaderRow] = useState(false);
	const handleSeparatorTypeChange = (event: SelectChangeEvent) => {
		setSeparatorType(+event.target.value);
		dispatch(filesExampleRequest({ fileId, separatorType: +event.target.value }));
	};

	const [inputArray, setInputArray] = useState<TInput[]>(initInputArray);
	const [selectedFieldsArray, setSelectedFieldsArray] = useState<boolean[]>([]);
	const [mappingPresetIndex, setMappingPresetIndex] = useState('');

	const handleCloseMappingFileDialog = () => {
		setSelectedFieldsArray([]);
		setMappingPresetIndex('');
		setClose();
	};

	useEffect(() => {
		setInputArray(initInputArray);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [exampleList]);

	const [openSaveMappingDialog, setOpenSaveMappingDialog] = useState(false);

	const handleOpenSaveMappingDialog = () => {
		setOpenSaveMappingDialog(true);
	};

	const handleCloseSaveMappingDialog = () => {
		setOpenSaveMappingDialog(false);
	};
	const handleSaveAndCloseSaveMappingDialog = () => {
		handleCloseSaveMappingDialog();
		handleCloseMappingFileDialog();
		const data: Record<TField['id'], number> = {};
		inputArray.forEach((column, index) => {
			if (column.name !== '--') {
				data[column.id] = index;
			}
		});

		dispatch(
			startUploading({
				fileId,
				clientId,
				body: { mappings: data, separatorType, isHeaderRow: headerRow },
			}),
		);
	};

	const updateSelectedFieldsArray = (inputArrayTmp: TInput[]) => {
		const selectedInputFieldsTmp = fieldsListArray.map(() => {
			return false;
		});
		for (let i = 0; i < inputArrayTmp.length; i += 1) {
			selectedInputFieldsTmp[inputArrayTmp[i].iIndex] = true;
		}
		selectedInputFieldsTmp[0] = false;
		setSelectedFieldsArray(selectedInputFieldsTmp);
	};

	const handleMappingPresetChange = (event: SelectChangeEvent) => {
		console.log('handleMappingPresetChange', event.target.value);
		setMappingPresetIndex(event.target.value);
		const map = JSON.parse(mappingPresetsList?.items[+event.target.value].json ?? '[]');
		const inputArrayTmp = [...inputArray];
		for (const key in map) {
			if (Object.prototype.hasOwnProperty.call(map, key)) {
				const index = map[key];
				console.log('!!!handleMappingPresetChange key', key);
				console.log('!!!handleMappingPresetChange index', index);
				const fieldsListArrayIndex = fieldsListArray.findIndex((el) => el.id === key);
				console.log('fieldsListArrayIndex', fieldsListArrayIndex);
				if (fieldsListArrayIndex > -1 && inputArrayTmp.length > index) {
					inputArrayTmp[index].id = key;
					inputArrayTmp[index].name = fieldsListArray[fieldsListArrayIndex]?.name ?? '?';
					inputArrayTmp[index].iIndex = fieldsListArrayIndex;
				} else {
					console.log('!!!error -1');
					if (fieldsListArrayIndex < 0)
						notificationContainer(`Mapping contains an invalid field value: "${key}"`, 'error');
				}
			}
		}
		setInputArray(inputArrayTmp);
		updateSelectedFieldsArray(inputArrayTmp);
	};

	const handleInputChange = (event: SelectChangeEvent, index: number) => {
		if (selectedFieldsArray[+event.target.value]) return;
		const inputArrayTmp = [...inputArray];
		if (fieldsListArray?.[+event.target.value]) {
			inputArrayTmp[index].id = fieldsListArray?.[+event.target.value].id;
			inputArrayTmp[index].name = fieldsListArray?.[+event.target.value].name;
			inputArrayTmp[index].iIndex = +event.target.value;
			const fieldsListArrayTmp = [...fieldsListArray];
			// fieldsListArrayTmp.splice(+event.target.value, 1);
			setFieldsListArray(fieldsListArrayTmp);
		}
		setInputArray(inputArrayTmp);
		updateSelectedFieldsArray(inputArrayTmp);
	};

	const makeMappingPresetJson = () => {
		const json = {};
		for (let i = 0; i < inputArray.length; i += 1) {
			// @ts-ignore
			if (inputArray[i].id !== '--') json[inputArray[i].id] = i;
		}
		return JSON.stringify(json);
	};

	return (
		<div style={{ width: '100%' }}>
			<Dialog
				fullScreen
				open={open}
				onClose={handleCloseMappingFileDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					<p>Mapping File. File Id: {fileId}</p>
					<FormControlLabel
						control={
							<Checkbox checked={headerRow} onChange={(e) => setHeaderRow(e.target.checked)} />
						}
						label="Header row"
					/>
					<FormControl fullWidth sx={{ width: '300px', marginLeft: '20px' }}>
						<InputLabel id="demo-simple-select-label">Separator Type</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={String(separatorType)}
							label="Separator type"
							onChange={handleSeparatorTypeChange}
						>
							{separatorsList?.map((item, index) => (
								<MenuItem key={item.name} value={index}>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl sx={{ width: '300px', marginLeft: '20px' }}>
						<InputLabel id="demo-simple-select-label">Mapping Preset</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={mappingPresetIndex}
							label="Separator type"
							onChange={handleMappingPresetChange}
						>
							{mappingPresetsList?.items?.map((item, index) => (
								<MenuItem key={item.name} value={String(index)}>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</DialogTitle>
				<DialogContent>
					{!exampleList?.Data.length && !exampleListIsLoad && (
						<div className="empty-data">
							<p className="empty__text">No Example List found...</p>
						</div>
					)}
					{exampleListIsLoad ? (
						<Loader />
					) : (
						<TableContainer component={Paper}>
							<Table sx={{ minWidth: 900 }} aria-label="simple table">
								<TableHead>
									<TableRow>
										{inputArray.map((item, index) => {
											return (
												<TableCell style={{ width: '200px', minWidth: '200px' }} key={nanoid()}>
													<FormControl size="small" fullWidth error={item.name === '---'}>
														<Select
															id="demo-simple-select"
															value={String(item.iIndex)}
															onChange={(e) => handleInputChange(e, index)}
															inputProps={{ 'aria-label': 'Without label' }}
															displayEmpty
														>
															{fieldsListArray?.map((iItem, iIndex) => (
																<MenuItem
																	key={iItem.name}
																	value={String(iIndex)}
																	style={{
																		backgroundColor: `${
																			selectedFieldsArray[iIndex] ? '#999' : 'white'
																		}`,
																	}}
																	disabled={selectedFieldsArray[iIndex]}
																>
																	{iItem.name}
																</MenuItem>
															))}
														</Select>
													</FormControl>
												</TableCell>
											);
										})}
									</TableRow>
								</TableHead>

								<TableBody>
									{exampleList?.Data.map((item, index) => (
										<TableRow key={nanoid()}>
											{item.map((i) => (
												<TableCell
													key={nanoid()}
													style={{
														width: '200px',
														minWidth: '200px',
														background: `${headerRow && index === 0 ? '#016193' : 'auto'}`,
														color: `${headerRow && index === 0 ? 'white' : 'auto'}`,
														opacity: `${headerRow && index === 0 ? '60%' : 'auto'}`,
													}}
												>
													{i}
												</TableCell>
											))}
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseMappingFileDialog}>Cancel</Button>
					<Button onClick={handleOpenSaveMappingDialog} autoFocus>
						Save mapping
					</Button>
				</DialogActions>
			</Dialog>
			<SaveMappingDialog
				clientId={Number(clientId)}
				open={openSaveMappingDialog}
				setClose={handleCloseSaveMappingDialog}
				setSaveAndClose={handleSaveAndCloseSaveMappingDialog}
				json={makeMappingPresetJson()}
			/>
		</div>
	);
};
