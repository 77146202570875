/* eslint-disable no-nested-ternary */
import { Button, Container, Paper, Stack, styled } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
	getClientFilesRequest,
	getClientRequest,
	startEasSendJobRequest,
} from 'redux/reducers/clients/reducer';
import { getClient } from 'redux/reducers/clients/selectors';
import {
	filesFieldsRequest,
	filesSeparatorsRequest,
	mappingPresets,
} from 'redux/reducers/files/reducer';
import { getPostFiles } from 'redux/reducers/files/selectors';
import RefreshIcon from '@mui/icons-material/Refresh';
import queryString from 'query-string';
import { getOrdersRequest } from 'redux/reducers/orders/reducer';
import { getSettingsRequest } from 'redux/reducers/settings/reducer';
import { ClientTabPanel } from './ClientTabPanel';
import { MappingFileDialog } from './MappingFileDialog';
import { UploadFileDialog } from './UploadFileDialog';
import { FilterMenu } from './FilterMenu';

export const Client: FC = () => {
	const params = useParams<{ clientId: string }>();
	const dispatch = useDispatch();
	const client = useSelector(getClient);
	const { clientId } = params;
	const { search } = useLocation();

	const { pageNumber, tab } = queryString.parse(search);

	const postFilesData = useSelector(getPostFiles);
	const [fileId, setFileId] = useState(0);

	useEffect(() => {
		dispatch(getClientRequest({ clientId: Number(clientId) }));
		dispatch(filesFieldsRequest());
		dispatch(filesSeparatorsRequest());
		dispatch(mappingPresets({ clientId: Number(clientId) }));
	}, [dispatch, clientId, params]);

	useEffect(() => {
		setFileId(postFilesData?.id ?? 0);
	}, [postFilesData]);

	const [openUploadFileDialog, setOpenUploadFileDialog] = useState(false);

	const handleOpenUploadFileDialog = () => {
		setOpenUploadFileDialog(true);
	};

	const handleCloseUploadFileDialog = () => {
		setOpenUploadFileDialog(false);
	};

	const [openMappingFileDialog, setOpenMappingFileDialog] = useState(false);

	const handleOpenMappingFileDialog = () => {
		setOpenMappingFileDialog(true);
	};

	const handleCloseMappingFileDialog = () => {
		setOpenMappingFileDialog(false);
	};

	const StyledButtonBlue = styled(Button)(() => ({
		':hover': {
			backgroundColor: '#5aa1bf',
		},
	}));
	const StyledButtonGreen = styled(Button)(() => ({
		':hover': {
			backgroundColor: '#7ea13a',
		},
	}));

	const handleStartEasSendJob = () => {
		dispatch(startEasSendJobRequest());
	};

	const handleRefresh = () => {
		const dispatchParams = {
			clientId: Number(clientId),
			pageSize: 10,
			pageNumber: +(pageNumber || 1),
		};
		+(tab ?? 0) === 0
			? dispatch(getClientFilesRequest(dispatchParams))
			: +(tab ?? 0) === 1
			? dispatch(mappingPresets({ clientId: Number(clientId) }))
			: +(tab ?? 0) === 2
			? dispatch(getSettingsRequest({ clientId: Number(clientId) }))
			: dispatch(getOrdersRequest(dispatchParams));
	};

	return (
		<>
			<div style={{ margin: '0 15px' }}>
				<Paper
					sx={{
						backgroundColor: '#016193',
						color: 'white',
						padding: 1,
						display: 'flex',
						justifyContent: 'space-between',
						borderRadius: '4px 4px 0 0',
					}}
				>
					<div>
						<span>
							Client id: <b>{client?.id}</b>
						</span>
						<span style={{ marginLeft: '20px' }}>
							Client name: <b>{client?.name}</b>
						</span>
					</div>
					<Stack direction="row" alignItems="center" spacing={2}>
						<RefreshIcon sx={{ cursor: 'pointer' }} onClick={handleRefresh} />
						{+(tab ?? 0) === 3 && <FilterMenu />}
						<StyledButtonBlue
							variant="contained"
							sx={{ backgroundColor: '#6ec1e4' }}
							onClick={handleOpenUploadFileDialog}
							size="small"
						>
							Upload file
						</StyledButtonBlue>
						<StyledButtonGreen
							variant="contained"
							sx={{ backgroundColor: '#95be47' }}
							onClick={handleStartEasSendJob}
							size="small"
						>
							Upload Data for EAS
						</StyledButtonGreen>
					</Stack>
				</Paper>
				<ClientTabPanel openMapping={handleOpenMappingFileDialog} setFileId={setFileId} />
			</div>
			<UploadFileDialog
				clientId={Number(clientId)}
				open={openUploadFileDialog}
				setClose={handleCloseUploadFileDialog}
				openMapping={handleOpenMappingFileDialog}
			/>
			<MappingFileDialog
				clientId={Number(clientId)}
				open={openMappingFileDialog}
				setClose={handleCloseMappingFileDialog}
				fileId={fileId}
			/>
		</>
	);
};
