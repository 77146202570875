import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import { TOrderBreakdownsData, TOrdersData, TOrdersStatusesData, TOrdersStore } from './types';

// ==========================================:
const getOrdersState = (state: TStoreState): TOrdersStore => state.orders;
export const getOrders = createSelector([getOrdersState], (orders: TOrdersStore) => orders);

// ====================================================:
export const getOrdersIsLoad = createSelector(
	[getOrders],
	(orders: TOrdersStore): boolean => orders.ordersLoader,
);

// ====================================================:
export const getOrdersList = createSelector(
	[getOrders],
	(orders: TOrdersStore): TOrdersData => orders.orders,
);

// ====================================================:
export const getOrdersStatuses = createSelector(
	[getOrders],
	(orders: TOrdersStore): TOrdersStatusesData => orders.ordersStatuses,
);

// ====================================================:
export const getOrderBreakdownsIsLoad = createSelector(
	[getOrders],
	(orders: TOrdersStore): boolean => orders.orderBreakdownsLoader,
);

// ====================================================:
export const getOrderBreakdownsList = createSelector(
	[getOrders],
	(orders: TOrdersStore): TOrderBreakdownsData => orders.orderBreakdowns,
);
