/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-for-in-array */
import { FC, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { getOrdersList } from 'redux/reducers/orders/selectors';
import queryString from 'query-string';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
	Badge,
	FormControl,
	InputLabel,
	ListItemIcon,
	ListItemText,
	Popover,
	Select,
	TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { getOrdersRequest } from 'redux/reducers/orders/reducer';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import AddIcon from '@mui/icons-material/Add';
import { AddFilterDialog } from './AddFilterDialog';

export const FilterMenu: FC = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [open, setOpen] = useState(false);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};
	const handleClose = () => {
		setAnchorEl(null);
		setOpen(false);
	};

	const params = useParams<{ clientId: string }>();
	const { clientId } = params;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { search } = useLocation();
	const orders = useSelector(getOrdersList);
	const navigateParams = queryString.parse(search);

	const [ordersFilters, setOrdersFilters] = useState<{ filter: string; value: string }[]>(
		orders?.headerItems?.map((item) => {
			return {
				filter: item.id,
				value: String(navigateParams?.[item.id] ?? ''),
			};
		}) ?? [],
	);

	const filters = orders?.headerItems?.map((item) => item.id) ?? [];
	const filtersNotSelected = filters.filter((itemFilters) => {
		return !ordersFilters.find(
			(itemOrders) => itemOrders.filter === itemFilters && itemOrders.value,
		);
	});
	const [addFilter, setAddFilter] = useState(filtersNotSelected[0]);
	const [addFilterIndex, setAddFilterIndex] = useState(filters.indexOf(filtersNotSelected[0]));

	useEffect(() => {
		setOrdersFilters(
			orders?.headerItems?.map((item) => {
				return {
					filter: item.id,
					value: String(navigateParams?.[item.id] ?? ''),
				};
			}) ?? [],
		);
	}, [search, orders]);

	const handleReset = () => {
		const dispatchParams = {
			clientId: Number(clientId),
			pageSize: 10,
			pageNumber: 1,
		};
		dispatch(getOrdersRequest(dispatchParams));
		navigate({
			pathname: location.pathname,
			search: `?tab=3&pageNumber=1`,
		});
		handleClose();
	};

	// const filtersParams = navigateParams.reduce((newMap, item, index) => {
	// 	return item !== '' ? newMap.set(orders?.headerItems[index].id, item) : newMap;
	// }, new Map());

	// filtersParams.forEach((val: string, key: string) => {
	// 	navigateParamsStr += `&${key}=${val}`;
	// });
	// const filtersParams: JSX.Element[] = [];
	// for (const key in navigateParams) {
	// 	if (
	// 		Object.prototype.hasOwnProperty.call(navigateParams, key) &&
	// 		key !== 'pageNumber' &&
	// 		key !== 'tab'
	// 	) {
	// 		filtersParams.push(
	// 			<MenuItem key={key}>
	// 				<ListItemText>{key} </ListItemText>
	// 				<TextField
	// 					size="small"
	// 					variant="outlined"
	// 					value={navigateParams[key]}
	// 					sx={{ mr: '10px', ml: '10px' }}
	// 				/>
	// 				<ListItemIcon>
	// 					<DeleteIcon fontSize="small" />
	// 				</ListItemIcon>
	// 			</MenuItem>,
	// 		);
	// 		navigateParams[key];
	// 	}
	// }

	const handleSetOrdersFilter = (filter: string, indexFilter = 0) => {
		const ordersFiltersTmp = [...ordersFilters];
		ordersFiltersTmp[indexFilter].value = String(filter);
		// setOrdersFilters(ordersFiltersTmp);
		const filtersParamsTmp = ordersFiltersTmp.reduce((newMap, item, index) => {
			return item.value !== '' ? newMap.set(orders?.headerItems[index].id, item.value) : newMap;
		}, new Map());
		const ordersParam = {
			clientId: Number(clientId),
			pageSize: 10,
			pageNumber: +(navigateParams.pageNumber || 1),
			...Object.fromEntries(filtersParamsTmp.entries()),
		};
		let navigateParamsStr = `?tab=3&pageNumber=${String(navigateParams.pageNumber || 1)}`;
		filtersParamsTmp.forEach((val: string, key: string) => {
			navigateParamsStr += `&${key}=${val}`;
		});
		navigate({
			pathname: location.pathname,
			search: navigateParamsStr,
		});

		dispatch(getOrdersRequest(ordersParam));
	};

	const handleAddFilter = () => {};

	const [openAddFilterDialog, setOpenAddFilterDialog] = useState(false);
	const handleOpenAddFilterDialog = () => {
		// setIndexFilterDialog(index);
		setOpenAddFilterDialog(true);
		setAddFilter(filtersNotSelected[0]);
	};
	const handleCloseAddFilterDialog = () => {
		setOpenAddFilterDialog(false);
	};

	return (
		<>
			<div>
				<Button
					id="basic-button"
					aria-controls={open ? 'basic-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					onClick={handleClick}
					variant="outlined"
					sx={{ color: 'white' }}
					// disabled={!ordersFilters.find((item) => item.value !== '')}
				>
					<Badge
						badgeContent={ordersFilters.filter((itemOrders) => itemOrders.value !== '').length}
						color="secondary"
					>
						<FilterAltIcon />
					</Badge>
				</Button>
				<Popover anchorEl={anchorEl} open={open} onClose={handleClose} style={{ padding: '30px' }}>
					{ordersFilters.find((itemOrders) => itemOrders.value !== '') ? (
						<MenuItem onClick={handleReset}>
							<ListItemText>
								<b>Reset all filters </b>
							</ListItemText>
							<ListItemIcon>
								<FilterAltOffIcon />
							</ListItemIcon>
						</MenuItem>
					) : (
						<MenuItem>
							<ListItemText sx={{ color: '#0000008a' }}>
								<b>Filters are not set </b>
							</ListItemText>
						</MenuItem>
					)}
					{ordersFilters.map((item, index) => {
						if (item.value !== '' && item.filter !== addFilter)
							return (
								<MenuItem key={item.filter}>
									<ListItemText>{item.filter} </ListItemText>
									<TextField
										size="small"
										variant="outlined"
										value={item.value}
										sx={{ mr: '10px', ml: '10px' }}
										onChange={(e) => handleSetOrdersFilter(e.target.value, index)}
									/>
									<ListItemIcon>
										<DeleteIcon fontSize="small" onClick={() => handleSetOrdersFilter('', index)} />
									</ListItemIcon>
								</MenuItem>
							);
						return '';
					})}
					{openAddFilterDialog && (
						<MenuItem key="FilterDialog">
							<ListItemText>
								<Select
									fullWidth
									size="small"
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={addFilter}
									onChange={(e) => setAddFilter(e.target.value)}
								>
									{filters
										.filter((itemFilters) => {
											return (
												!ordersFilters.find(
													(itemOrders) => itemOrders.filter === itemFilters && itemOrders.value,
												) || addFilter === itemFilters
											);
										})
										.map((itemFilter, index) => (
											<MenuItem value={itemFilter}>{itemFilter}</MenuItem>
										))}
								</Select>
							</ListItemText>

							<TextField
								size="small"
								variant="outlined"
								value={ordersFilters[filters.indexOf(addFilter)].value ?? filtersNotSelected[0]}
								sx={{ mr: '10px', ml: '10px' }}
								onChange={(e) => handleSetOrdersFilter(e.target.value, filters.indexOf(addFilter))}
							/>
							<ListItemIcon>
								<DeleteIcon
									fontSize="small"
									onClick={() => {
										handleSetOrdersFilter('', filters.indexOf(addFilter));
										setOpenAddFilterDialog(false);
									}}
								/>
							</ListItemIcon>
						</MenuItem>
					)}
					<MenuItem onClick={handleOpenAddFilterDialog}>
						<ListItemText>
							<b>Add filter </b>
						</ListItemText>
						<ListItemIcon>
							<AddIcon />
						</ListItemIcon>
					</MenuItem>
				</Popover>
			</div>
			{/* <AddFilterDialog
				open={openAddFilterDialog}
				setClose={handleCloseAddFilterDialog}
				setFilter={handleSetOrdersFilter}
				// nameFilter={orders.headerItems[AddFilterDialog].name}
				// filter={ordersFilters[AddFilterDialog]}
				ordersFilters={ordersFilters}
				filters={orders?.headerItems.map((item) => item.id) ?? []}
			/> */}
		</>
	);
};
