export const ORDERS_STATUS_CREATED = 'Created';
export const ORDERS_STATUS_PROCESSING = 'In progress';
export const ORDERS_STATUS_COMPLETED = 'Completed';
export const ORDERS_STATUS_ERROR = 'Error';

export const ordersStatus = {
	CREATED: {
		name: ORDERS_STATUS_CREATED,
		num: 0,
	},
	PROCESSING: {
		name: ORDERS_STATUS_PROCESSING,
		num: 1,
	},
	COMPLETED: {
		name: ORDERS_STATUS_COMPLETED,
		num: 2,
	},
	ERROR: {
		name: ORDERS_STATUS_ERROR,
		num: 3,
	},
};
export const ordersStatusArray = [
	ordersStatus.CREATED.name,
	ordersStatus.PROCESSING.name,
	ordersStatus.COMPLETED.name,
	ordersStatus.ERROR.name,
];
