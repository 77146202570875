/* eslint-disable no-nested-ternary */
import { FC } from 'react';
import { TUploadCsvFileStatusProps } from './types';
import {
	STATUS_IMPORT_FAILED,
	STATUS_PARTIALLY_PROCESSED,
	STATUS_PREPROCESSED,
	STATUS_PREPROCESSING,
	STATUS_PREPROCESSING_FAILED,
	STATUS_PROCESSED,
	STATUS_VERIFYING_BY_OPERATOR,
} from '../constants';

export const UploadCsvFileStatus: FC<TUploadCsvFileStatusProps> = ({ status }) => {
	return (
		<span>
			{status === STATUS_PROCESSED ? (
				<b style={{ color: 'green' }}>{status}</b>
			) : status === STATUS_PREPROCESSING ? (
				<b style={{ color: '#6ec1e4' }}>{status}</b>
			) : status === STATUS_PREPROCESSED ? (
				<b style={{ color: '#006293' }}>{status}</b>
			) : status === STATUS_PREPROCESSING_FAILED ? (
				<b style={{ color: '#ff6c00' }}>{status}</b>
			) : status === STATUS_VERIFYING_BY_OPERATOR ? (
				<b style={{ color: 'violet' }}>{status}</b>
			) : status === STATUS_IMPORT_FAILED ? (
				<b style={{ color: 'red' }}>{status}</b>
			) : status === STATUS_PARTIALLY_PROCESSED ? (
				<b style={{ color: '#95be47' }}>{status}</b>
			) : (
				<b style={{ color: 'gray' }}>{status}</b>
			)}
		</span>
	);
};
