import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import {
	TFilesExampleData,
	TFilesFieldsData,
	TFilesSeparatorsData,
	TFilesStore,
	TMappingPresetsData,
	TPostFilesData,
} from './types';

// ==========================================:
const getFilesState = (state: TStoreState): TFilesStore => state.files;
export const getFiles = createSelector([getFilesState], (files: TFilesStore) => files);

// ====================================================:
export const getFilesFieldsList = createSelector(
	[getFiles],
	(files: TFilesStore): TFilesFieldsData => files.filesFields,
);

// ====================================================:
export const getPostFiles = createSelector(
	[getFiles],
	(files: TFilesStore): TPostFilesData => files.postFilesResponse,
);

// ====================================================:
export const getFilesSeparatorsList = createSelector(
	[getFiles],
	(files: TFilesStore): TFilesSeparatorsData => files.filesSeparators,
);

// ====================================================:
export const getFilesExampleList = createSelector(
	[getFiles],
	(files: TFilesStore): TFilesExampleData => files.filesExample,
);

// ====================================================:
export const getFilesExampleListIsLoad = createSelector(
	[getFiles],
	(files: TFilesStore): boolean => files.filesExampleLoader,
);

// ====================================================:
export const getMappingPresets = createSelector(
	[getFiles],
	(files: TFilesStore): TMappingPresetsData => files.mappingPresets,
);
// ====================================================:
export const getMappingPresetsIsLoad = createSelector(
	[getFiles],
	(files: TFilesStore): boolean => files.mappingPresetsLoader,
);
