/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { TActionChoiceDialogProps } from './types';

export const ActionChoiceDialog: FC<TActionChoiceDialogProps> = ({
	clientId,
	open,
	setClose,
	editCell,
	blockCell,
	orderId,
	setAsFilter,
}) => {
	const navigate = useNavigate();
	const { search } = useLocation();

	const { pageNumber } = queryString.parse(search);

	const handleEditCell = () => {
		editCell();
	};

	const handlerOrderClick = () => {
		navigate(
			`/client/${String(clientId)}/order/${String(orderId)}?fromPage=${String(pageNumber ?? 1)}`,
		);
	};

	return (
		<div style={{ minWidth: '900px' }}>
			<Dialog
				open={open}
				onClose={setClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Choose an action</DialogTitle>

				<DialogActions>
					<Button onClick={setClose}>Cancel</Button>
					<Button onClick={setAsFilter}>Set as filter</Button>

					<Button onClick={handleEditCell} disabled={blockCell}>
						Edit cell
					</Button>

					<Button onClick={handlerOrderClick}>Open Order</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
