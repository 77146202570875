/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-nested-ternary */
import {
	Checkbox,
	FormControlLabel,
	Paper,
	Switch,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	Typography,
} from '@mui/material';
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getSettingsRequest, postSettingsUpdateRequest } from 'redux/reducers/settings/reducer';
import { getSettingsIsLoad, getSettingsList } from 'redux/reducers/settings/selectors';
import { EditCellInput } from 'ui/EditCellInput';
import { Loader } from 'ui/Loader';
import { StyledTableCell, StyledTableRow } from 'ui/StyledTable';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Accordion,
	AccordionDetails,
	AccordionDetailsBlue,
	AccordionDetailsGreen,
	AccordionGray,
	AccordionSummary,
	AccordionSummaryGray,
} from 'ui/StyledAccordion';
import EditIcon from '@mui/icons-material/Edit';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import _ from 'lodash';
import settingsJSON from './settings.json';
import { TYPE_OF_SETTINGS_RECIPIENTS, TYPE_OF_SETTINGS_SETTINGS } from './constants';
import { TTypeOfSettings } from './types';

export const ClientSettingsList: FC = () => {
	const params = useParams<{ clientId: string }>();
	const { clientId } = params;

	const dispatch = useDispatch();
	const settings = useSelector(getSettingsList);
	const settingsIsLoad = useSelector(getSettingsIsLoad);

	const [typeOfSettings, setTypeOfSettings] = useState<TTypeOfSettings>(TYPE_OF_SETTINGS_SETTINGS);
	const [mainIndex, setMainIndex] = useState(0);
	const [settingsIndex, setSettingsIndex] = useState(0);
	const [recipientsIndex, setRecipientsIndex] = useState(0);
	const [recipientsSettingsIndex, setRecipientsSettingsIndex] = useState(0);

	const [cellValue, setCellValue] = useState<string | number | boolean>('');
	const [editCell, setEditCell] = useState(false);

	const [accordion1Expanded, setAccordion1Expanded] = useState<string | false>(false);
	const [accordion2Expanded, setAccordion2Expanded] = useState<string | false>(false);
	const [accordion3Expanded, setAccordion3Expanded] = useState<string | false>(false);

	const [editing, setEditing] = useState(false);
	// const [settings, setSettings] = useState(settingsJSON);

	const handleEditing = () => {
		setEditing(true);
	};
	const handleNotEditing = () => {
		setEditing(false);
	};

	useEffect(() => {
		dispatch(getSettingsRequest({ clientId: Number(clientId) }));
	}, [dispatch, clientId]);

	const handleEditValue = (
		value: string | number | boolean,
		indx: number,
		settingsIndx: number,
		typeSettings: TTypeOfSettings,
		recipientsIndx?: number,
		recipientsSettingsIndx?: number,
	) => {
		setCellValue(value);
		setMainIndex(indx);
		setSettingsIndex(settingsIndx);
		setEditCell(true);
		setTypeOfSettings(typeSettings);
		setRecipientsIndex(recipientsIndx ?? 0);
		setRecipientsSettingsIndex(recipientsSettingsIndx ?? 0);
	};

	const handleChangeCellValue = (value: string) => {
		setCellValue(value);
	};

	const handleCancelChangeCellValue = () => {
		setEditCell(false);
		setCellValue('');
	};
	const handleDispatchChangeCellValue = () => {
		handleCancelChangeCellValue();
		const settingsTmp = _.cloneDeep(settings);
		if (settingsTmp) {
			if (typeOfSettings === TYPE_OF_SETTINGS_SETTINGS)
				settingsTmp[mainIndex].settings[settingsIndex].value = cellValue;
			else if (typeOfSettings === TYPE_OF_SETTINGS_RECIPIENTS)
				settingsTmp[mainIndex].recipients[recipientsIndex].settings[recipientsSettingsIndex].value =
					cellValue;
			dispatch(postSettingsUpdateRequest({ clientId: Number(clientId ?? '0'), body: settingsTmp }));
		}
	};

	const handleRecipientsListChange = (
		settingsIndx: number,
		recipientsIndx: number,
		available: boolean,
	) => {
		const settingsTmp = _.cloneDeep(settings);
		if (settingsTmp) {
			settingsTmp[settingsIndx].recipients[recipientsIndx].available = available;
			// setSettings(settingsTmp);
			dispatch(postSettingsUpdateRequest({ clientId: Number(clientId ?? '0'), body: settingsTmp }));
		}
	};

	const handleAccordion1Change =
		(panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
			setAccordion1Expanded(isExpanded ? panel : false);
			setAccordion2Expanded(false);
			setAccordion3Expanded(false);
			handleCancelChangeCellValue();
		};
	const handleAccordion2Change =
		(panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
			setAccordion2Expanded(isExpanded ? panel : false);
			setAccordion3Expanded(false);
			handleCancelChangeCellValue();
		};
	const handleAccordion3Change =
		(panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
			setAccordion3Expanded(isExpanded ? panel : false);
			handleCancelChangeCellValue();
		};

	return (
		<>
			{!settings?.length && !settingsIsLoad && (
				<div className="empty-data">
					<p className="empty__text">No settings found...</p>
				</div>
			)}

			{settingsIsLoad && <Loader />}

			{!!settings?.length && !settingsIsLoad && (
				<Paper sx={{ mt: '-24px', ml: '-24px', mr: '-24px' }}>
					{/* <TableContainer sx={{ maxHeight: 800 }}>
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<StyledTableRow>
									<StyledTableCell align="center" colSpan={4}>
										Name
									</StyledTableCell>
									<StyledTableCell
										align="center"
										colSpan={1}
										sx={{
											minWidth: '200px',
										}}
									>
										Value
									</StyledTableCell>
								</StyledTableRow>
							</TableHead>
							<TableBody>
								{settings?.settings?.map((item, index) => (
									<StyledTableRow hover key={item.settingId}>
										<StyledTableCell align="center" colSpan={1}>
											{item.name}
										</StyledTableCell>
										<StyledTableCell
											align="center"
											colSpan={4}
											sx={{
												maxWidth: '400px',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
												whiteSpace: 'nowrap',
											}}
											onClick={() => handleEditValue(item?.value ?? '', index)}
										>
											{editCell && settingsIndex === index ? (
												<EditCellInput
													placeholder={item?.name}
													value={cellValue}
													changeValue={handleChangeCellValue}
													cancel={handleCancelChangeCellValue}
													check={handleDispatchChangeCellValue}
													type={
														item.type === 'String'
															? String(cellValue)?.length > 20
																? 'textarea'
																: 'input'
															: item.type === 'Boolean'
															? 'boolean'
															: item.type === 'Number'
															? 'number'
															: 'input'
													}
												/>
											) : item.type === 'Boolean' ? (
												// <Checkbox checked={item.value === 'true'} />
												<CheckIcon color={item.value === 'true' ? 'inherit' : 'disabled'} />
											) : (
												item.value
											)}
										</StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer> */}
					<div>
						{settings.map((item, index) => (
							<AccordionGray
								expanded={accordion1Expanded === `panel${index}`}
								onChange={handleAccordion1Change(`panel${index}`)}
							>
								<AccordionSummaryGray
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1bh-content"
									id="panel1bh-header"
								>
									<Typography sx={{ width: '50%', flexShrink: 0 }}>{item.name}</Typography>
									<Typography sx={{ color: 'text.secondary' }}>
										source settings: {item.settings.length}
										{`, `}
										recipients: {item.recipients.length}
									</Typography>
								</AccordionSummaryGray>
								<AccordionDetails>
									<div
										style={{
											borderLeft: '1px solid #ff840347',
											height: 'calc(100% - 48px)',
											position: 'absolute',
											marginTop: '-16px',
											marginLeft: '15px',
										}}
									/>
									<Accordion
										expanded={accordion2Expanded === `panel2Settings-${index}`}
										onChange={handleAccordion2Change(`panel2Settings-${index}`)}
										sx={{ marginLeft: '20px' }}
									>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls="panel1bh-content"
											id="panel1bh-header"
										>
											<Typography sx={{ width: '50%', flexShrink: 0 }}>Settings</Typography>
										</AccordionSummary>
										<AccordionDetailsGreen>
											<div
												style={{
													borderLeft: '1px solid #88cf0385',
													height: 'calc(100% - 48px)',
													position: 'absolute',
													marginTop: '-16px',
													marginLeft: '15px',
												}}
											/>
											{item.settings.map((settingsItem, indexItem) => (
												<Typography
													sx={{ display: 'flex', marginBottom: '10px', marginLeft: '50px' }}
													onClick={() =>
														handleEditValue(
															settingsItem?.value ?? '',
															index,
															indexItem,
															TYPE_OF_SETTINGS_SETTINGS,
														)
													}
												>
													<Typography component="p" sx={{ width: '50%', flexShrink: 0 }}>
														{settingsItem.name}
													</Typography>
													<Typography component="p" sx={{ color: 'text.secondary' }}>
														{editCell && settingsIndex === indexItem ? (
															<EditCellInput
																placeholder={settingsItem?.name}
																value={cellValue}
																changeValue={handleChangeCellValue}
																cancel={handleCancelChangeCellValue}
																check={handleDispatchChangeCellValue}
																type={
																	settingsItem.type === 'String'
																		? String(cellValue)?.length > 20
																			? 'textarea'
																			: 'input'
																		: settingsItem.type === 'Boolean'
																		? 'boolean'
																		: settingsItem.type === 'Number'
																		? 'number'
																		: 'input'
																}
															/>
														) : settingsItem.type === 'Boolean' ? (
															// <Checkbox checked={item.value === 'true'} />
															<CheckIcon
																color={settingsItem.value === 'true' ? 'inherit' : 'disabled'}
															/>
														) : (
															settingsItem.value
														)}
													</Typography>
												</Typography>
											))}
										</AccordionDetailsGreen>
									</Accordion>
									<Accordion
										expanded={accordion2Expanded === `panel2Recipients-${index}`}
										onChange={handleAccordion2Change(`panel2Recipients-${index}`)}
										sx={{ marginLeft: '20px' }}
									>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls="panel1bh-content"
											id="panel1bh-header"
										>
											<Typography sx={{ width: '50%', flexShrink: 0 }}>Recipients</Typography>
										</AccordionSummary>
										<AccordionDetailsGreen>
											<div
												style={{
													borderLeft: '1px solid #8ad604c2',
													height: 'calc(100% - 30px)',
													position: 'absolute',
													marginTop: '-16px',
													marginLeft: '15px',
												}}
											/>
											{item.recipients.map((recipientsItem, recipientsIndx) => {
												if (!recipientsItem.available && !editing) return <div />;
												if (editing)
													return (
														<div style={{ marginLeft: '40px', marginBottom: '10px' }}>
															<Checkbox
																checked={recipientsItem.available}
																onChange={() =>
																	handleRecipientsListChange(
																		index,
																		recipientsIndx,
																		!recipientsItem.available,
																	)
																}
															/>
															{recipientsItem.name}
														</div>
													);
												return (
													<Accordion
														expanded={
															accordion3Expanded === `panel3Recipients-${index}-${recipientsIndx}`
														}
														onChange={handleAccordion3Change(
															`panel3Recipients-${index}-${recipientsIndx}`,
														)}
														sx={{ marginLeft: '20px' }}
														className={`${recipientsItem.available ? '' : 'hidden'}`}
													>
														<AccordionSummary
															expandIcon={<ExpandMoreIcon />}
															aria-controls="panel1bh-content"
															id="panel1bh-header"
														>
															<Typography sx={{ width: '50%', flexShrink: 0 }}>
																{recipientsItem.name}
															</Typography>
														</AccordionSummary>
														<AccordionDetailsBlue>
															<div
																style={{
																	borderLeft: '1px solid #08cdb869',
																	height: 'calc(100% - 40px)',
																	position: 'absolute',
																	marginTop: '-16px',
																	marginLeft: '15px',
																}}
															/>
															{recipientsItem.settings.map(
																(recipientsSettingsItem, recipientsSettingsIndx) => (
																	<Typography
																		sx={{
																			display: 'flex',
																			marginBottom: '10px',
																			marginLeft: '50px',
																		}}
																		onClick={() =>
																			handleEditValue(
																				recipientsSettingsItem?.value ?? '',
																				index,
																				0,
																				TYPE_OF_SETTINGS_RECIPIENTS,
																				recipientsIndx,
																				recipientsSettingsIndx,
																			)
																		}
																	>
																		<Typography component="p" sx={{ width: '50%', flexShrink: 0 }}>
																			{recipientsSettingsItem.name}
																		</Typography>
																		<Typography component="p" sx={{ color: 'text.secondary' }}>
																			{editCell &&
																			typeOfSettings === TYPE_OF_SETTINGS_RECIPIENTS &&
																			recipientsIndex === recipientsIndx &&
																			recipientsSettingsIndex === recipientsSettingsIndx ? (
																				<EditCellInput
																					placeholder={recipientsSettingsItem?.name}
																					value={cellValue}
																					changeValue={handleChangeCellValue}
																					cancel={handleCancelChangeCellValue}
																					check={handleDispatchChangeCellValue}
																					type={
																						recipientsSettingsItem.type === 'String'
																							? String(cellValue)?.length > 20
																								? 'textarea'
																								: 'input'
																							: recipientsSettingsItem.type === 'Boolean'
																							? 'boolean'
																							: recipientsSettingsItem.type === 'Number'
																							? 'number'
																							: 'input'
																					}
																				/>
																			) : recipientsSettingsItem.type === 'Boolean' ? (
																				// <Checkbox checked={item.value === 'true'} />
																				<CheckIcon
																					color={
																						recipientsSettingsItem.value === 'true'
																							? 'inherit'
																							: 'disabled'
																					}
																				/>
																			) : (
																				recipientsSettingsItem.value
																			)}
																		</Typography>
																	</Typography>
																),
															)}
														</AccordionDetailsBlue>
													</Accordion>
												);
											})}
											{/* <hr
												style={{
													marginLeft: '20px',
													backgroundColor: '#08cdb869',
													// borderColor: '#08cdb869',
													// color: '#08cdb869',
													borderTop: '1px solid #08cdb869',
												}}
											/> */}
											<FormControlLabel
												sx={{
													marginTop: '10px',
													marginLeft: '30px',
												}}
												control={
													<Switch
														defaultChecked
														onChange={(e) => setEditing(e.target.checked)}
														checked={editing}
													/>
												}
												label="Edit recipients list"
											/>
											{/* <Typography
												sx={{
													display: 'flex',
													marginTop: '10px',
													cursor: 'pointer',
												}}
												color="primary"
												onClick={() => setEditing(!editing)}
											>
												<NoteAddIcon
													sx={{
														marginLeft: '40px',
														marginRight: '10px',
													}}
												/>{' '}
												Add New Recipient
											</Typography> */}
										</AccordionDetailsGreen>
									</Accordion>
								</AccordionDetails>
							</AccordionGray>
						))}
					</div>
				</Paper>
			)}
		</>
	);
};
