export const PATHS = {
	LOGIN_PAGE: `/login`,
	HOME_PAGE: `/`,
	CLIENT_PAGE: `/client/:clientId`,
	ORDER_PAGE: `/client/:clientId/order/:orderId`,
	VAT_REPORT_PAGE: `/vat-report`,
	VAT_REPORT_DOWNLOAD_PAGE: `/vat-report/:taskId`,
	IFRAME_PAGE: `/iframe`,
	VAT_PAGE: `/vat`,
	SWED_BANK_INTEGRATION_PAGE: `/swed-bank-integration`,
	UPLOAD_CSV_FILE_PAGE: `/upload-csv-file`,
	MONTHLY_FEE_PAGE: `/monthly-fee`,
};
