/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-array-index-key */
import {
	Chip,
	Pagination,
	Paper,
	Stack,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
	getOrdersRequest,
	getOrdersStatusesRequest,
	putOrderUpdateRequest,
} from 'redux/reducers/orders/reducer';
import { getOrdersIsLoad, getOrdersList, getOrdersStatuses } from 'redux/reducers/orders/selectors';
import { TOrdersRequestPayload } from 'redux/reducers/orders/types';
import { Loader } from 'ui/Loader';
import {
	LightTooltip,
	StyledTableCell,
	StyledTableCellHover,
	StyledTableRow,
} from 'ui/StyledTable';
import { ordersStatus } from 'redux/reducers/orders/constants';
import { EditCellInput } from 'ui/EditCellInput';
import queryString from 'query-string';
import { ActionChoiceDialog } from './ActionСhoiceDialog';
import { FilterDialog } from './FilterDialog';
import { PAGE_SIZE } from './constants';

export const ClientOrdersList: FC = () => {
	const isNumber = (n: any) => {
		if (!isNaN(parseFloat(n)) && isFinite(n)) return true;
		return false;
	};
	const params = useParams<{ clientId: string }>();
	const { clientId } = params;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { search } = useLocation();

	const navigateParams = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState(Number(navigateParams.pageNumber) || 1);

	const orders = useSelector(getOrdersList);
	const ordersIsLoad = useSelector(getOrdersIsLoad);
	const ordersStatuses = useSelector(getOrdersStatuses);
	const [rowIndex, setRowIndex] = useState(0);
	const [cellIndex, setCellIndex] = useState(0);
	const [cellValue, setCellValue] = useState<string | number | boolean>('');
	const [blockCell, setBlockCell] = useState(false);
	const [editCell, setEditCell] = useState(false);
	const [openActionChoiceDialog, setOpenActionChoiceDialog] = useState(false);
	const handleOpenActionChoiceDialog = (
		i: string,
		index: number,
		iIndex: number,
		block: boolean,
	) => {
		setCellValue(i);
		setRowIndex(index);
		setCellIndex(iIndex);
		setOpenActionChoiceDialog(true);
		setEditCell(false);
		setBlockCell(block);
	};
	const handleCloseActionChoiceDialog = () => {
		setOpenActionChoiceDialog(false);
	};

	const [openFilterDialog, setOpenFilterDialog] = useState(false);
	const [indexFilterDialog, setIndexFilterDialog] = useState(0);
	const [ordersFilters, setOrdersFilters] = useState<string[]>(
		orders?.headerItems?.map((item) => {
			return String(navigateParams?.[item.id] ?? '');
		}) ?? [],
	);

	useEffect(() => {
		setOrdersFilters(
			orders?.headerItems?.map((item) => {
				return String(navigateParams?.[item.id] ?? '');
			}) ?? [],
		);
	}, [orders]);

	const handleOpenFilterDialog = (index: number) => {
		setIndexFilterDialog(index);
		setOpenFilterDialog(true);
	};
	const handleCloseFilterDialog = () => {
		setOpenFilterDialog(false);
	};

	const handleSetOrdersFilter = (filter: string, indexFilter: number = indexFilterDialog) => {
		handleCloseActionChoiceDialog();
		const ordersFiltersTmp = [...ordersFilters];
		ordersFiltersTmp[indexFilter] = String(filter);
		setOrdersFilters(ordersFiltersTmp);
		const filtersParams = ordersFiltersTmp.reduce((newMap, item, index) => {
			return item !== '' ? newMap.set(orders?.headerItems[index].id, item) : newMap;
		}, new Map());
		const ordersParam = {
			clientId: Number(clientId),
			pageSize: 10,
			pageNumber: +(navigateParams.pageNumber || 1),
			...Object.fromEntries(filtersParams.entries()),
		};
		let navigateParamsStr = `?tab=3&pageNumber=${String(navigateParams.pageNumber || 1)}`;
		filtersParams.forEach((val: string, key: string) => {
			navigateParamsStr += `&${key}=${val}`;
		});
		navigate({
			pathname: location.pathname,
			search: navigateParamsStr,
		});

		dispatch(getOrdersRequest(ordersParam));
	};

	useEffect(() => {
		const filtersParams = ordersFilters.reduce((newMap, item, index) => {
			return item !== '' ? newMap.set(orders?.headerItems[index].id, item) : newMap;
		}, new Map());
		const ordersParam = {
			clientId: Number(clientId),
			pageSize: PAGE_SIZE,
			pageNumber: +(navigateParams.pageNumber || 1),
			...Object.fromEntries(filtersParams.entries()),
		};
		dispatch(getOrdersRequest(ordersParam));

		dispatch(getOrdersStatusesRequest());
	}, [clientId, dispatch]);

	const handlePageChange = (event: unknown, page: number) => {
		setCurrentPage(page);
		const filtersParams = ordersFilters.reduce((newMap, item, index) => {
			return item !== '' ? newMap.set(orders?.headerItems[index].id, item) : newMap;
		}, new Map());
		const paramsHandle = {
			clientId: Number(clientId),
			pageSize: PAGE_SIZE,
			pageNumber: page || 1,
			...Object.fromEntries(filtersParams.entries()),
		};
		let navigateParamsStr = `?tab=3&pageNumber=${String(page)}`;
		filtersParams.forEach((val: string, key: string) => {
			navigateParamsStr += `&${key}=${val}`;
		});
		navigate({
			pathname: location.pathname,
			search: navigateParamsStr,
		});
		dispatch(getOrdersRequest(paramsHandle));
	};

	const handleEditCell = () => {
		setEditCell(true);
		handleCloseActionChoiceDialog();
	};

	const handleChangeCellValue = (value: string) => {
		setCellValue(value);
	};

	const handleCancelChangeCellValue = () => {
		setEditCell(false);
		setCellValue('');
	};
	const handleDispatchChangeCellValue = () => {
		setEditCell(false);
		setCellValue('');
		dispatch(
			putOrderUpdateRequest({
				orderId: orders?.items[rowIndex][0] ?? '',
				clientId: clientId ?? '',
				pageNumber: +(navigateParams.pageNumber || 1),
				body: [
					{
						fieldId: orders?.headerItems[cellIndex].id ?? '',
						newFieldValue: String(cellValue),
					},
				],
			}),
		);
	};
	const statusIndex = useMemo(
		() => orders?.headerItems?.findIndex((el) => el?.id === 'OrderStatus') ?? 0,
		[orders],
	);

	return (
		<div>
			{!orders?.items?.length && !orders?.headerItems?.length && !ordersIsLoad && (
				<div className="empty-data">
					<p className="empty__text">No orders found...</p>
				</div>
			)}
			{ordersIsLoad && <Loader />}
			{!!orders?.items?.length && !!orders?.headerItems?.length && !ordersIsLoad && (
				<>
					<Paper
						sx={{ overflowX: 'scroll', mt: '-24px', ml: '-24px', mr: '-24px', borderRadius: '0' }}
					>
						<TableContainer>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										{orders?.headerItems?.map(
											(headerItem, index) =>
												headerItem.isUserVisible && (
													<LightTooltip
														// title={`filter: ${
														// 	ordersFilters[index] === '' ? '--' : ordersFilters[index]
														// }`}
														title={
															<>
																<small color="inherit">Filter:</small>
																<br />
																<b>
																	{ordersFilters[index] === '' ? '\u2014' : ordersFilters[index]}
																</b>
															</>
														}
														placement="top"
														arrow
													>
														<StyledTableCell
															align="center"
															colSpan={index}
															key={nanoid()}
															hidden={!headerItem.isUserVisible}
															className={ordersFilters[index] !== '' ? 'filter' : ''}
															sx={
																headerItem.name.length > 30
																	? { minWidth: '380px' }
																	: headerItem.name.length > 25
																	? { minWidth: '280px' }
																	: headerItem.name.length > 18
																	? { minWidth: '220px' }
																	: headerItem.name.length > 12
																	? { minWidth: '120px' }
																	: headerItem.name.length > 6
																	? { minWidth: '80px' }
																	: { minWidth: 'auto' }
															}
															onClick={() => handleOpenFilterDialog(index)}
														>
															{headerItem.name}
														</StyledTableCell>
													</LightTooltip>
												),
										)}
									</TableRow>
								</TableHead>
								<TableBody>
									{orders?.items?.map((item, index) => (
										<StyledTableRow
											key={`row${index}`}
											className={`${editCell && rowIndex === index ? 'edit' : ''} ${
												item?.[statusIndex ?? 0] === '3' ? 'error' : ''
											}`}
										>
											{item?.map(
												(i, iIndex) =>
													orders?.headerItems[iIndex].isUserVisible && (
														<StyledTableCellHover
															align="center"
															colSpan={iIndex}
															key={`cell${index}-${iIndex}`}
															onClick={() =>
																handleOpenActionChoiceDialog(
																	i,
																	index,
																	iIndex,
																	!orders?.headerItems[iIndex].isEdit ||
																		item?.[statusIndex ?? 0] === '2' ||
																		item?.[statusIndex ?? 0] === '1',
																)
															}
															className={`${editCell && rowIndex === index ? 'edit' : ''} ${
																item?.[statusIndex ?? 0] === '3' ? 'error' : ''
															} ${item?.[statusIndex ?? 0] === '0' ? 'created' : ''} ${
																item?.[statusIndex ?? 0] === '1' ? 'process' : ''
															} ${item?.[statusIndex ?? 0] === '2' ? 'completed' : ''}`}
															sx={
																i?.length > 35
																	? { minWidth: '380px' }
																	: i?.length > 25
																	? { minWidth: '280px' }
																	: i?.length > 18
																	? { minWidth: '220px' }
																	: i?.length > 12
																	? { minWidth: '120px' }
																	: i?.length > 6
																	? { minWidth: '80px' }
																	: { minWidth: 'auto' }
															}
														>
															{editCell && rowIndex === index && cellIndex === iIndex ? (
																<EditCellInput
																	placeholder={orders?.headerItems[iIndex].name}
																	value={cellValue}
																	changeValue={handleChangeCellValue}
																	cancel={handleCancelChangeCellValue}
																	check={handleDispatchChangeCellValue}
																/>
															) : orders?.headerItems[iIndex].id === 'OrderStatus' ? (
																<Chip
																	label={ordersStatuses?.[+i].name}
																	sx={{
																		bgcolor: `${
																			+i === ordersStatus.CREATED.num
																				? '#016193'
																				: +i === ordersStatus.COMPLETED.num
																				? '#7ea13a'
																				: +i === ordersStatus.ERROR.num
																				? '#ff007f'
																				: '#e1be0d'
																		}`,
																		color: 'white',
																	}}
																	size="small"
																/>
															) : (
																i
															)}
														</StyledTableCellHover>
													),
											)}
										</StyledTableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>

					<Stack spacing={2} marginTop={2}>
						<Pagination
							count={Math.ceil((orders?.itemsCount ?? 0) / PAGE_SIZE)}
							page={currentPage}
							onChange={handlePageChange}
							shape="rounded"
							color="primary"
						/>
					</Stack>
					<ActionChoiceDialog
						clientId={Number(clientId)}
						orderId={Number(orders?.items[rowIndex][0] ?? '0')}
						open={openActionChoiceDialog}
						setClose={handleCloseActionChoiceDialog}
						editCell={handleEditCell}
						blockCell={blockCell}
						setAsFilter={() => handleSetOrdersFilter(String(cellValue), cellIndex)}
					/>
					<FilterDialog
						open={openFilterDialog}
						setClose={handleCloseFilterDialog}
						setFilter={handleSetOrdersFilter}
						nameFilter={orders.headerItems[indexFilterDialog].name}
						filter={ordersFilters[indexFilterDialog]}
					/>
				</>
			)}
		</div>
	);
};
