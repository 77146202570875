/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { DialogContent, TextField } from '@mui/material';
import { TFilterDialogProps } from './types';

export const FilterDialog: FC<TFilterDialogProps> = ({
	open,
	setClose,
	setFilter,
	nameFilter,
	filter,
}) => {
	const [filterDialog, setFilterDialog] = useState(filter);
	const setFilterHandler = () => {
		setFilter(filterDialog);
		setClose();
	};
	const resetFilterHandler = () => {
		setFilter('');
		setClose();
	};

	useEffect(() => {
		setFilterDialog(filter);
	}, [filter]);

	return (
		<div style={{ minWidth: '900px' }}>
			<Dialog
				open={open}
				onClose={setClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Set filter for {nameFilter}</DialogTitle>

				<DialogContent sx={{ width: '260px' }}>
					<TextField
						label="Filter"
						variant="outlined"
						sx={{ marginTop: '5px' }}
						onChange={(e) => setFilterDialog(e.target.value)}
						fullWidth
						value={filterDialog}
					/>
				</DialogContent>

				<DialogActions>
					<Button onClick={setClose}>Cancel</Button>
					<Button onClick={resetFilterHandler}>Reset filter</Button>
					<Button onClick={setFilterHandler}>Set filter</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
