/* eslint-disable import/extensions */
import { ClientPage } from 'pages/ClientPage';
import { HomePage } from 'pages/HomePage';
import { IframePage } from 'pages/IframePage';
import { LoginPage } from 'pages/LoginPage';
import { MonthlyFeePage } from 'pages/MonthlyFeePage';
import { OrderPage } from 'pages/OrderPage';
import { SwedBankIntegrationPage } from 'pages/SwedBankIntegrationPage';
import { UploadCsvFilePage } from 'pages/UploadCsvFilePage';
import { VATPage } from 'pages/VATPage';
import { VATReportDownloadPage } from 'pages/VATReportDownloadPage';
import { VATReportPage } from 'pages/VATReportPage';
import { useRoutes } from 'react-router';
import { PATHS } from './constants';

export const NavigateList = () => {
	const routes = useRoutes([
		{
			path: PATHS.LOGIN_PAGE,
			element: <LoginPage />,
		},
		{
			path: PATHS.HOME_PAGE,
			element: <HomePage />,
		},
		{
			path: PATHS.CLIENT_PAGE,
			element: <ClientPage />,
		},
		{
			path: PATHS.ORDER_PAGE,
			element: <OrderPage />,
		},
		{
			path: PATHS.VAT_REPORT_PAGE,
			element: <VATReportPage />,
		},
		{
			path: PATHS.VAT_REPORT_DOWNLOAD_PAGE,
			element: <VATReportDownloadPage />,
		},

		{
			path: PATHS.IFRAME_PAGE,
			element: <IframePage />,
		},
		{
			path: PATHS.VAT_PAGE,
			element: <VATPage />,
		},
		{
			path: PATHS.SWED_BANK_INTEGRATION_PAGE,
			element: <SwedBankIntegrationPage />,
		},
		{
			path: PATHS.UPLOAD_CSV_FILE_PAGE,
			element: <UploadCsvFilePage />,
		},
		{
			path: PATHS.MONTHLY_FEE_PAGE,
			element: <MonthlyFeePage />,
		},
	]);
	return routes;
};
