/* eslint-disable no-nested-ternary */
import { Checkbox, Divider, IconButton, InputBase, Paper, TextField } from '@mui/material';
import { FC } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import { TEditCellInputProps } from './types';

export const EditCellInput: FC<TEditCellInputProps> = ({
	placeholder,
	value,
	changeValue,
	cancel,
	check,
	type,
}) => {
	const n = type === 'textarea' ? '100%' : '300px';
	return (
		<Paper
			component="form"
			sx={{
				p: '2px 4px',
				display: 'flex',
				alignItems: 'center',
				width: n,
			}}
			onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
		>
			{type === 'textarea' ? (
				<TextField
					label={placeholder}
					placeholder={placeholder}
					multiline
					rows={4}
					value={String(value)}
					onChange={(e) => changeValue(e.target.value)}
					sx={{ width: n, m: '20px 0 20px 20px' }}
				/>
			) : type === 'number' ? (
				<InputBase
					sx={{ ml: 1, flex: 1 }}
					placeholder={placeholder}
					value={value}
					onChange={(e) => changeValue(e.target.value)}
					autoFocus
					type="number"
				/>
			) : type === 'boolean' ? (
				<Checkbox
					sx={{ ml: 1, flex: 1 }}
					placeholder={placeholder}
					checked={value === 'true'}
					onChange={(e) => changeValue(String(e.target.checked))}
					autoFocus
				/>
			) : (
				// type === 'input'
				<InputBase
					sx={{ ml: 1, flex: 1 }}
					placeholder={placeholder}
					value={value}
					onChange={(e) => changeValue(e.target.value)}
					autoFocus
				/>
			)}
			<IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={cancel}>
				<CancelIcon />
			</IconButton>
			<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
			<IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={check}>
				<CheckIcon />
			</IconButton>
		</Paper>
	);
};
