/* eslint-disable react/require-default-props */
import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { FC, ReactNode, useState } from 'react';
import { styled } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { getOrdersList } from 'redux/reducers/orders/selectors';
import { ClientFilesList } from './ClientFilesList';
import { TClientTabPanelProps } from './types';
import { MappingPresetsList } from './MappingPresetsList';
import { ClientOrdersList } from './ClientOrdersList';
import { ClientSettingsList } from './ClientSettingsList';

interface TabPanelProps {
	children?: ReactNode;
	dir?: string;
	index: number;
	value: number;
}

const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
};

const a11yProps = (index: number) => {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
};

export const ClientTabPanel: FC<TClientTabPanelProps> = ({ openMapping, setFileId }) => {
	const theme = useTheme();
	const navigate = useNavigate();
	const location = useLocation();
	const { search } = useLocation();
	const { tab } = queryString.parse(search);
	const navigateParams = queryString.parse(search);
	const orders = useSelector(getOrdersList);

	const [value, setValue] = useState(+(tab ?? 0));

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
		const ordersFilters =
			orders?.headerItems?.reduce((acc, item) => {
				if (navigateParams?.[item.id])
					return `${acc}&${item.id}=${String(navigateParams?.[item.id] ?? '')}`;
				return acc;
			}, '') ?? '';
		navigate({
			pathname: location.pathname,
			search: `?tab=${String(newValue)}${ordersFilters}`,
		});
	};

	const handleChangeIndex = (index: number) => {
		setValue(index);
	};

	const StyledTabs = styled(Tabs)(() => ({
		'&  .MuiTabs-indicator': {
			backgroundColor: '#95be47',
			height: ' 4px',
		},
	}));

	return (
		<Box sx={{ bgcolor: 'background.paper' }}>
			<AppBar position="static" sx={{ bgcolor: '#016193', boxShadow: 0 }}>
				<StyledTabs
					value={value}
					onChange={handleChange}
					indicatorColor="secondary"
					textColor="inherit"
					variant="fullWidth"
					aria-label="full width tabs example"
					sx={{ borderBottom: '2px solid #95be47', height: '10px' }}
				>
					<Tab label="Files" {...a11yProps(0)} sx={{ height: '10px' }} />
					<Tab label="Csv Mappings" {...a11yProps(1)} />
					<Tab label="Settings" {...a11yProps(2)} />
					<Tab label="Orders" {...a11yProps(3)} />
				</StyledTabs>
			</AppBar>
			<SwipeableViews
				axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
				index={value}
				onChangeIndex={handleChangeIndex}
				style={{ padding: 0 }}
			>
				<TabPanel value={value} index={0} dir={theme.direction}>
					<ClientFilesList openMapping={openMapping} setFileId={setFileId} />
				</TabPanel>
				<TabPanel value={value} index={1} dir={theme.direction}>
					<MappingPresetsList />
				</TabPanel>
				<TabPanel value={value} index={2} dir={theme.direction}>
					<ClientSettingsList />
				</TabPanel>
				<TabPanel value={value} index={3} dir={theme.direction}>
					<ClientOrdersList />
				</TabPanel>
			</SwipeableViews>
		</Box>
	);
};
