import { type } from 'os';

export interface ISwedBankIntegrationItem {
	docNum: number;
	paymentDate: string;
	clientIBAN: string;
	clientName: string;
	bank: string;
	amount: number;
	processedDate: string;
	paymentDescription: string;
	regNum: string;
	EASInvoiceNum: string;
	status: TSwedBankIntegrationStatus;
}

export const PROCESSED_STATUS = 'Processed';
export const TO_BE_MANUALLY_PROCESSED_STATUS = 'To be manually processed';
export const INVOICE_NOT_FOUND_STATUS = 'Invoice not found';
export type TSwedBankIntegrationStatus =
	| typeof PROCESSED_STATUS
	| typeof TO_BE_MANUALLY_PROCESSED_STATUS
	| typeof INVOICE_NOT_FOUND_STATUS;
