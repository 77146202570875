import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { FC, useEffect, useState } from 'react';
import {
	DialogContent,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getIntegrationTypes } from 'redux/reducers/clients/selectors';
import { getIntegrationTypesRequest, postClientRequest } from 'redux/reducers/clients/reducer';
import { TAddClientDialogProps } from './types';

export const AddClientDialog: FC<TAddClientDialogProps> = ({ open, setClose }) => {
	const integrationTypes = useSelector(getIntegrationTypes);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getIntegrationTypesRequest());
	}, [dispatch]);

	const [selectIntegrationTypes, setSelectIntegrationTypes] = useState('');
	const [name, setName] = useState('');

	const handleAddClient = () => {
		dispatch(postClientRequest({ name, integrationSystemTypeId: +selectIntegrationTypes }));
	};

	const handleIntegrationTypesChange = (e: SelectChangeEvent) => {
		setSelectIntegrationTypes(e.target.value);
	};

	return (
		<Dialog
			open={open}
			onClose={setClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">Add new client</DialogTitle>
			<DialogContent sx={{ width: '260px' }}>
				<TextField
					label="Name of Client"
					variant="outlined"
					sx={{ marginTop: '5px' }}
					onChange={(e) => setName(e.target.value)}
					fullWidth
				/>

				<FormControl fullWidth sx={{ marginTop: '20px' }}>
					<InputLabel id="integration-types-select-label">Integration Type</InputLabel>
					<Select
						labelId="integration-types-select-label"
						id="integration-types-select"
						value={selectIntegrationTypes}
						label="Integration type"
						onChange={handleIntegrationTypesChange}
					>
						{integrationTypes?.map((item) => (
							<MenuItem key={item.name} value={item.id}>
								{item.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</DialogContent>

			<DialogActions>
				<Button onClick={setClose}>Cancel</Button>
				<Button onClick={handleAddClient} autoFocus disabled={!selectIntegrationTypes || !name}>
					Add new client
				</Button>
			</DialogActions>
		</Dialog>
	);
};
