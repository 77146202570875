import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { FC } from 'react';

export const Loader: FC = () => {
	return (
		<Box
			sx={{
				display: 'flex',
				border: '1px solid #e6e8ec',
				justifyContent: 'center',
				alignItems: 'center',
				minHeight: '400px',
			}}
		>
			<CircularProgress />
		</Box>
	);
};
