import {
	Button,
	CircularProgress,
	Container,
	Pagination,
	Paper,
	Stack,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	styled,
} from '@mui/material';
import { FC, useState } from 'react';
import { Loader } from 'ui/Loader';
import { StyledTableCell, StyledTableRow } from 'ui/StyledTable';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { UploadCsvFileStatus } from './UploadCsvFileStatus';
import { TUploadCsvFile } from './UploadCsvFileStatus/types';
import {
	STATUS_IMPORT_FAILED,
	STATUS_PARTIALLY_PROCESSED,
	STATUS_PREPROCESSED,
	STATUS_PREPROCESSING,
	STATUS_PREPROCESSING_FAILED,
	STATUS_PROCESSED,
	STATUS_VERIFYING_BY_OPERATOR,
} from './constants';
import { DownloadDialog } from './DownloadDialog';

const StyledButtonBlue = styled(Button)(() => ({
	':hover': {
		backgroundColor: '#5aa1bf',
	},
}));
const StyledButtonGreen = styled(Button)(() => ({
	':hover': {
		backgroundColor: '#7ea13a',
	},
}));

export const UploadCsvFile: FC = () => {
	const files: TUploadCsvFile[] = [
		{
			id: 1,
			name: 'file1',
			comment: 'comment1',
			totalLines: 100,
			numberOfOrders: 50,
			numbersOfAcceptedOrders: 30,
			numberOfSkippedOrders: 20,
			numberOfRejectedOrders: 20,
			uploadAt: '12.06.2023',
			uploadedBy: 'user1',
			processedAt: '12.06.2023',
			status: STATUS_PROCESSED,
		},
		{
			id: 2,
			name: 'file2',
			comment: 'comment2',
			totalLines: 100,
			numberOfOrders: 50,
			numbersOfAcceptedOrders: 30,
			numberOfSkippedOrders: 20,
			numberOfRejectedOrders: 20,
			uploadAt: '12.06.2023',
			uploadedBy: 'user2',
			processedAt: '12.06.2023',
			status: STATUS_PREPROCESSING,
		},
		{
			id: 3,
			name: 'file3',
			comment: 'comment3',
			totalLines: 100,
			numberOfOrders: 50,
			numbersOfAcceptedOrders: 30,
			numberOfSkippedOrders: 20,
			numberOfRejectedOrders: 20,
			uploadAt: '12.06.2023',
			uploadedBy: 'user3',
			processedAt: '12.06.2023',
			status: STATUS_PREPROCESSED,
		},
		{
			id: 4,
			name: 'file4',
			comment: 'comment4',
			totalLines: 100,
			numberOfOrders: 50,
			numbersOfAcceptedOrders: 30,
			numberOfSkippedOrders: 20,
			numberOfRejectedOrders: 20,
			uploadAt: '12.06.2023',
			uploadedBy: 'user4',
			processedAt: '12.06.2023',
			status: STATUS_PREPROCESSING_FAILED,
		},
		{
			id: 5,
			name: 'file5',
			comment: 'comment5',
			totalLines: 100,
			numberOfOrders: 50,
			numbersOfAcceptedOrders: 30,
			numberOfSkippedOrders: 20,
			numberOfRejectedOrders: 20,
			uploadAt: '12.06.2023',
			uploadedBy: 'user5',
			processedAt: '12.06.2023',
			status: STATUS_VERIFYING_BY_OPERATOR,
		},
		{
			id: 6,
			name: 'file6',
			comment: 'comment6',
			totalLines: 100,
			numberOfOrders: 50,
			numbersOfAcceptedOrders: 30,
			numberOfSkippedOrders: 20,
			numberOfRejectedOrders: 20,
			uploadAt: '12.06.2023',
			uploadedBy: 'user6',
			processedAt: '12.06.2023',
			status: STATUS_IMPORT_FAILED,
		},
		{
			id: 7,
			name: 'file7',
			comment: 'comment7',
			totalLines: 100,
			numberOfOrders: 50,
			numbersOfAcceptedOrders: 30,
			numberOfSkippedOrders: 20,
			numberOfRejectedOrders: 20,
			uploadAt: '12.06.2023',
			uploadedBy: 'user7',
			processedAt: '12.06.2023',
			status: STATUS_PARTIALLY_PROCESSED,
		},
	];

	const filesIsLoad = false;

	const stateDownloadFilesInit = files.map((file) => ({
		id: file.id,
		isDownload: false,
		isDownloadReport: false,
	}));

	const [stateDownloadFiles, setStateDownloadFiles] = useState(stateDownloadFilesInit);

	const [openDownloadFileDialog, setOpenDownloadFileDialog] = useState(false);
	const [indexDownloadFile, setIndexDownloadFile] = useState(0);
	const handleCloseDownloadFileDialog = () => setOpenDownloadFileDialog(false);
	const handleOpenDownloadFileDialog = (index: number) => {
		setOpenDownloadFileDialog(true);
		setIndexDownloadFile(index);
	};
	const handleDownloadFile = () => {
		setOpenDownloadFileDialog(false);
		setStateDownloadFiles((prevState) => {
			const newState = [...prevState];
			newState[indexDownloadFile].isDownload = true;
			return newState;
		});
		console.log('stateDownloadFiles', stateDownloadFiles);
	};

	const [openDownloadReportDialog, setOpenDownloadReportDialog] = useState(false);
	const [indexDownloadReport, setIndexDownloadReport] = useState(0);

	const handleCloseDownloadReportDialog = () => setOpenDownloadReportDialog(false);
	const handleOpenDownloadReportDialog = (index: number) => {
		setOpenDownloadReportDialog(true);
		setIndexDownloadReport(index);
	};
	const handleDownloadReport = () => {
		setOpenDownloadReportDialog(false);
		setStateDownloadFiles((prevState) => {
			const newState = [...prevState];
			newState[indexDownloadReport].isDownloadReport = true;
			return newState;
		});
	};

	const [openUploadFileDialog, setOpenUploadFileDialog] = useState(false);
	const handleCloseUploadFileDialog = () => setOpenUploadFileDialog(false);
	const handleOpenUploadFileDialog = () => setOpenUploadFileDialog(true);
	const handleUploadFile = () => setOpenUploadFileDialog(false);

	const [openDownloadTemplateDialog, setOpenDownloadTemplateDialog] = useState(false);
	const [isDownloadTemplate, setIsDownloadTemplate] = useState(false);
	const handleCloseDownloadTemplateDialog = () => setOpenDownloadTemplateDialog(false);
	const handleOpenDownloadTemplateDialog = () => setOpenDownloadTemplateDialog(true);
	const handleDownloadTemplate = () => {
		setOpenDownloadTemplateDialog(false);
		setIsDownloadTemplate(true);
	};

	return (
		<div>
			{/* <h1>UploadCsvFile</h1> */}
			<Container maxWidth="xl">
				<Paper
					sx={{
						backgroundColor: '#016193',
						color: 'white',
						padding: 1,
						display: 'flex',
						justifyContent: 'space-between',
						borderRadius: '4px 4px 0 0',
					}}
				>
					<div>
						{/* <span>
							Client id: <b>35378485</b>
						</span>
						<span style={{ marginLeft: '20px' }}>
							Client name: <b>WWWWWW</b>
						</span> */}
					</div>
					<Stack direction="row" alignItems="center" spacing={2}>
						{/* <RefreshIcon sx={{ cursor: 'pointer' }} onClick={handleRefresh} />
						{+(tab ?? 0) === 3 && <FilterMenu />} */}
						<StyledButtonBlue
							variant="contained"
							sx={{ backgroundColor: '#6ec1e4' }}
							onClick={handleOpenUploadFileDialog}
							size="small"
						>
							Upload CSV file
						</StyledButtonBlue>
						<StyledButtonGreen
							variant="contained"
							sx={{ backgroundColor: '#95be47' }}
							onClick={handleOpenDownloadTemplateDialog}
							size="small"
						>
							{isDownloadTemplate ? (
								<>
									<CircularProgress size={20} style={{ color: 'white', marginRight: '20px' }} />{' '}
									Downloading...
								</>
							) : (
								'Download template'
							)}
						</StyledButtonGreen>
					</Stack>
				</Paper>

				{!files.length && !filesIsLoad && (
					<div className="empty-data">
						<p className="empty__text">No clients found...</p>
					</div>
				)}
				{!!filesIsLoad && <Loader />}
				{files.length && !filesIsLoad && (
					<>
						<Paper>
							<TableContainer sx={{ maxHeight: 800 }}>
								<Table stickyHeader aria-label="sticky table">
									<TableHead>
										<TableRow>
											<StyledTableCell align="center" colSpan={1}>
												id
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={2}>
												File Name
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={2}>
												Comments
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={2}>
												Total Lines
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={2}>
												Number of Orders
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={2}>
												Number of Accepted Orders
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={2}>
												Number of Skipped Orders
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={2}>
												Number of Rejected Orders
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={2}>
												Upload At
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={2}>
												Uploaded By
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={2}>
												Processed At
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={2}>
												Status
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={1}>
												Download File
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={1}>
												Download Report
											</StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{files.map((item, index) => (
											<StyledTableRow
												hover
												key={item.id}
												// onClick={() => handlerClientClick(item.id)}
											>
												<StyledTableCell align="center" colSpan={1}>
													{item.id}
												</StyledTableCell>
												<StyledTableCell align="center" colSpan={2}>
													{item.name}
												</StyledTableCell>
												<StyledTableCell align="center" colSpan={2}>
													{item.comment}
												</StyledTableCell>
												<StyledTableCell align="center" colSpan={2}>
													{item.totalLines}
												</StyledTableCell>
												<StyledTableCell align="center" colSpan={2}>
													{item.numberOfOrders}
												</StyledTableCell>
												<StyledTableCell align="center" colSpan={2}>
													{item.numbersOfAcceptedOrders}
												</StyledTableCell>
												<StyledTableCell align="center" colSpan={2}>
													{item.numberOfSkippedOrders}
												</StyledTableCell>
												<StyledTableCell align="center" colSpan={2}>
													{item.numberOfRejectedOrders}
												</StyledTableCell>
												<StyledTableCell align="center" colSpan={2}>
													{item.uploadAt}
												</StyledTableCell>
												<StyledTableCell align="center" colSpan={2}>
													{item.uploadedBy}
												</StyledTableCell>
												<StyledTableCell align="center" colSpan={2}>
													{item.processedAt}
												</StyledTableCell>
												<StyledTableCell align="center" colSpan={2}>
													<UploadCsvFileStatus status={item.status} />
												</StyledTableCell>

												<StyledTableCell align="center" colSpan={1}>
													{stateDownloadFiles[index].isDownload ? (
														<CircularProgress size={20} />
													) : (
														<FileDownloadIcon
															sx={{ color: '#016193', cursor: 'pointer' }}
															onClick={(e) => {
																e.stopPropagation();
																handleOpenDownloadFileDialog(index);
															}}
														/>
													)}
												</StyledTableCell>
												<StyledTableCell align="center" colSpan={1}>
													{stateDownloadFiles[index].isDownloadReport ? (
														<CircularProgress size={20} />
													) : (
														<SimCardDownloadIcon
															sx={{ color: '#016193', cursor: 'pointer' }}
															onClick={(e) => {
																e.stopPropagation();
																handleOpenDownloadReportDialog(index);
															}}
														/>
													)}
												</StyledTableCell>
											</StyledTableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Paper>
						{/* {files.length > 10 && (
							<Stack spacing={2} marginTop={2}>
								<Pagination
									count={Math.ceil((clients.clients?.itemsCount ?? 0) / 10)}
									page={currentPage}
									onChange={handlePageChange}
									// variant="outlined"
									shape="rounded"
									color="primary"
								/>
							</Stack>
						)}
						<DeleteDialog
							open={openDeleteDialog}
							setClose={handleCloseDeleteDialog}
							handleDelete={handleDeleteClient}
							text="Are you sure you want to delete this client?"
						/>
					</>
				)} */}
					</>
				)}
			</Container>
			<DownloadDialog
				open={openDownloadFileDialog}
				setClose={handleCloseDownloadFileDialog}
				handler={handleDownloadFile}
				text={`Do you wont to download file ${String(files?.[indexDownloadFile]?.name ?? '')}?`}
				nameButton="Download File"
			/>
			<DownloadDialog
				open={openDownloadReportDialog}
				setClose={handleCloseDownloadReportDialog}
				handler={handleDownloadReport}
				text="Do you wont to download report?"
				nameButton="Download Report"
			/>
			<DownloadDialog
				open={openDownloadTemplateDialog}
				setClose={handleCloseDownloadTemplateDialog}
				handler={handleDownloadTemplate}
				text="Do you wont to download template?"
				nameButton="Download Template"
			/>
			<DownloadDialog
				open={openUploadFileDialog}
				setClose={handleCloseUploadFileDialog}
				handler={handleUploadFile}
				text="Do you wont to upload file?"
				nameButton="Upload File"
			/>
		</div>
	);
};
