import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import {
	TClientsStore,
	TClientsData,
	TClientData,
	TClientFilesData,
	TIntegrationTypesData,
} from './types';

// ==========================================:
const getClientsState = (state: TStoreState): TClientsStore => state.clients;
export const getClients = createSelector([getClientsState], (clients: TClientsStore) => clients);

// ====================================================:
export const getClientsIsLoad = createSelector(
	[getClients],
	(clients: TClientsStore): boolean => clients.clientsLoader,
);

// ====================================================:
export const getClientsList = createSelector(
	[getClients],
	(clients: TClientsStore): TClientsData => clients.clients,
);

// ====================================================:
export const getClientIsLoad = createSelector(
	[getClients],
	(clients: TClientsStore): boolean => clients.clientLoader,
);

// ====================================================:
export const getClient = createSelector(
	[getClients],
	(clients: TClientsStore): TClientData => clients.client,
);

// ====================================================:
export const getClientFilesIsLoad = createSelector(
	[getClients],
	(clients: TClientsStore): boolean => clients.clientFilesLoader,
);

// ====================================================:
export const getClientFiles = createSelector(
	[getClients],
	(clients: TClientsStore): TClientFilesData => clients.clientFiles,
);

// ====================================================:
export const getIntegrationTypesIsLoad = createSelector(
	[getClients],
	(clients: TClientsStore): boolean => clients.integrationTypesLoader,
);

// ====================================================:
export const getIntegrationTypes = createSelector(
	[getClients],
	(clients: TClientsStore): TIntegrationTypesData => clients.integrationTypes,
);
