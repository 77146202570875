/* eslint-disable react-hooks/rules-of-hooks */
import {
	Button,
	Container,
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { FC } from 'react';
import { StyledTableCell, StyledTableRow } from 'ui/StyledTable';

export const VAT: FC = () => {
	return (
		<div>
			<Container maxWidth="lg" sx={{ marginTop: '40px' }}>
				<Paper>
					<TableContainer sx={{ maxHeight: 800 }}>
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									<StyledTableCell align="center" colSpan={2}>
										Quarter
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										Amount per quarter
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										1st month
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										2nd month
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										3rd month
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										Action
									</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<StyledTableCell align="center" colSpan={1} sx={{ color: '#006293' }}>
										<b>2022</b>
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={8} />
								</TableRow>

								<StyledTableRow hover>
									<StyledTableCell align="center" colSpan={2}>
										1st quarter
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										1078.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										304.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										304.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										304.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										<b style={{ color: '#006293' }}>Approved</b>
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow hover>
									<StyledTableCell align="center" colSpan={2}>
										2nd quarter
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										1078.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										304.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										304.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										304.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										<b style={{ color: '#006293' }}>Approved</b>
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow hover>
									<StyledTableCell align="center" colSpan={2}>
										3rd quarter
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										1078.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										304.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										304.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										304.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										<b style={{ color: '#006293' }}>Approved</b>
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow hover>
									<StyledTableCell align="center" colSpan={2}>
										4th quarter
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										1078.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										304.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										304.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										304.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										<Button variant="contained" size="small">
											Approve
										</Button>
									</StyledTableCell>
								</StyledTableRow>

								<TableRow>
									<StyledTableCell align="center" colSpan={1} sx={{ color: '#006293' }}>
										<b>2023</b>
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={8} />
								</TableRow>

								<StyledTableRow hover>
									<StyledTableCell align="center" colSpan={2}>
										1st quarter
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										1078.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										304.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										304.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										304.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										<Button variant="contained" size="small">
											Approve
										</Button>
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow hover>
									<StyledTableCell align="center" colSpan={2}>
										2nd quarter
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										1078.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										304.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										304.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										304.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										<Button variant="contained" size="small">
											Approve
										</Button>
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow hover>
									<StyledTableCell align="center" colSpan={2}>
										3rd quarter
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										1078.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										304.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										304.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										304.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										<Button variant="contained" size="small">
											Approve
										</Button>
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow hover>
									<StyledTableCell align="center" colSpan={2}>
										4th quarter
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										1078.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										304.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										304.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										304.65
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										<Button variant="contained" size="small">
											Approve
										</Button>
									</StyledTableCell>
								</StyledTableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Container>
		</div>
	);
};
