import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { AddClientDialog } from 'layouts/Header/AddClientDialog';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { startEasSendJobRequest } from 'redux/reducers/clients/reducer';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { authInitState } from 'redux/reducers/auth/reducer';
import { PATHS } from 'routes/constants';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import Face from '@mui/icons-material/Face';

const ResponsiveAppBar = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
	const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const handleLogout = () => {
		setAnchorElUser(null);
		dispatch(authInitState());
	};

	const [openAddClientDialog, setOpenAddClientDialog] = useState(false);

	const handleOpenAddClientDialog = () => {
		handleCloseNavMenu();
		setOpenAddClientDialog(true);
	};

	const handleCloseAddClientDialog = () => {
		setOpenAddClientDialog(false);
	};
	const handleStartEasSendJob = () => {
		handleCloseNavMenu();
		dispatch(startEasSendJobRequest());
	};

	const handleToClients = () => {
		navigate(PATHS.CLIENT_PAGE);
	};
	const handleToVATReport = () => {
		navigate('/vat-report/new');
	};
	const handleToIframe = () => {
		navigate(PATHS.IFRAME_PAGE);
	};
	const handleToVAT = () => {
		navigate(PATHS.VAT_PAGE);
	};

	const handleToSwedBankIntegration = () => {
		navigate(PATHS.SWED_BANK_INTEGRATION_PAGE);
	};

	const handleToUploadCsvFile = () => {
		navigate(PATHS.UPLOAD_CSV_FILE_PAGE);
	};

	const handleMonthlyFee = () => {
		navigate(PATHS.MONTHLY_FEE_PAGE);
	};

	const NAVIGATE = [
		{
			name: 'List of Clients',
			path: PATHS.HOME_PAGE,
		},
		{
			name: 'IOSS VAT Report',
			path: '/vat-report/new',
		},
		// {
		// 	name: 'Iframe',
		// 	path: PATHS.IFRAME_PAGE,
		// },
		{
			name: 'Approve VAT Report',
			path: PATHS.VAT_PAGE,
		},
		{
			name: 'Swed bank integration',
			path: PATHS.SWED_BANK_INTEGRATION_PAGE,
		},
		{
			name: 'Upload CSV File',
			path: PATHS.UPLOAD_CSV_FILE_PAGE,
		},
		{
			name: 'Monthly Fee',
			path: PATHS.MONTHLY_FEE_PAGE,
		},
	];
	return (
		<>
			<AppBar
				position="static"
				sx={{ marginBottom: 2, backgroundColor: 'white', color: '#016193' }}
			>
				<div style={{ margin: '0 15px' }}>
					<Toolbar disableGutters>
						{/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
						<Avatar
							alt="MCI"
							src="/img/EAS-logo-new.svg"
							sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}
						/>
						<Typography
							variant="h6"
							noWrap
							component="a"
							href="/"
							sx={{
								mr: 2,
								display: { xs: 'none', md: 'flex' },
								fontFamily: 'monospace',
								fontWeight: 700,
								letterSpacing: '.3rem',
								color: 'inherit',
								textDecoration: 'none',
							}}
						>
							MCI
						</Typography>
						<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
							<IconButton
								size="large"
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleOpenNavMenu}
								color="inherit"
							>
								<MenuIcon />
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorElNav}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
								open={Boolean(anchorElNav)}
								onClose={handleCloseNavMenu}
								sx={{
									display: { xs: 'block', md: 'none' },
								}}
							>
								<MenuItem onClick={handleToClients}>
									<Typography textAlign="center">List of Clients</Typography>
								</MenuItem>
								<MenuItem onClick={handleOpenAddClientDialog}>
									<Typography textAlign="center">Add Client</Typography>
								</MenuItem>
								<MenuItem onClick={handleStartEasSendJob}>
									<Typography textAlign="center">Upload Data for EAS</Typography>
								</MenuItem>
							</Menu>
						</Box>
						<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
							{NAVIGATE.map((item, index) => (
								<Button
									key={item.path}
									onClick={() => navigate(item.path)}
									sx={{
										my: 2,
										color: matchPath(location.pathname, item.path) ? '#5aa1bf' : 'primary',
										display: 'block',
									}}
								>
									{item.name}
								</Button>
							))}
							{/* <Button onClick={handleToClients} sx={{ my: 2, color: '#016193', display: 'block' }}>
								List of Clients
							</Button>
							<Button
								onClick={handleToVATReport}
								sx={{ my: 2, color: '#016193', display: 'block' }}
							>
								IOSS VAT Report
							</Button>
							<Button onClick={handleToIframe} sx={{ my: 2, color: '#016193', display: 'block' }}>
								Iframe
							</Button>
							<Button onClick={handleToVAT} sx={{ my: 2, color: '#016193', display: 'block' }}>
								Approve VAT Report
							</Button>
							<Button
								onClick={handleToSwedBankIntegration}
								sx={{ my: 2, color: '#016193', display: 'block' }}
							>
								Swed bank integration
							</Button>
							<Button
								onClick={handleToUploadCsvFile}
								sx={{ my: 2, color: '#016193', display: 'block' }}
							>
								Upload CSV File
							</Button>
							<Button onClick={handleMonthlyFee} sx={{ my: 2, color: '#016193', display: 'block' }}>
								Monthly Fee
							</Button> */}
						</Box>
						<Avatar
							alt="MCI"
							src="/img/new-logo.webp"
							sx={{ display: { xs: 'flex', md: 'none' }, mr: 1, ml: 2 }}
						/>

						<Typography
							variant="h5"
							noWrap
							component="a"
							href="/"
							sx={{
								mr: 2,
								display: { xs: 'flex', md: 'none' },
								flexGrow: 1,
								fontFamily: 'monospace',
								fontWeight: 700,
								letterSpacing: '.3rem',
								color: 'inherit',
								textDecoration: 'none',
							}}
						>
							MCI
						</Typography>
						<Box sx={{ flexGrow: 0 }}>
							<Tooltip title="Add Client">
								<IconButton onClick={handleOpenAddClientDialog} sx={{ color: '#016193', mr: 2 }}>
									<PersonAddIcon />
								</IconButton>
							</Tooltip>
							<Tooltip title="Upload Data for EAS" sx={{ color: '#016193' }}>
								<IconButton onClick={handleStartEasSendJob} sx={{ my: 2, color: '#016193', mr: 2 }}>
									<UploadFileIcon />
								</IconButton>
							</Tooltip>

							<Tooltip title="Open settings">
								<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
									<Avatar sx={{ bgcolor: '#ddd' }} src="/img/user.png" />
								</IconButton>
							</Tooltip>
							<Menu
								sx={{ mt: '45px' }}
								id="menu-appbar"
								anchorEl={anchorElUser}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(anchorElUser)}
								onClose={handleCloseUserMenu}
							>
								<MenuItem onClick={handleLogout}>
									<Typography textAlign="center">Logout</Typography>
								</MenuItem>
							</Menu>
						</Box>
					</Toolbar>
				</div>
			</AppBar>
			<AddClientDialog open={openAddClientDialog} setClose={handleCloseAddClientDialog} />
		</>
	);
};
export default ResponsiveAppBar;
