export const FILE_STATUS_CREATED = 'Created';
export const FILE_STATUS_PROCESSING = 'In progress';
export const FILE_STATUS_FINISHED = 'Finished';

export const fileStatus = {
	CREATED: {
		name: FILE_STATUS_CREATED,
		num: 0,
	},
	PROCESSING: {
		name: FILE_STATUS_PROCESSING,
		num: 1,
	},
	FINISHED: {
		name: FILE_STATUS_FINISHED,
		num: 2,
	},
};
export const fileStatusArray = [
	fileStatus.CREATED.name,
	fileStatus.PROCESSING.name,
	fileStatus.FINISHED.name,
];

export const INTEGRATION_SYSTEM_TYPE_COMMON_CSV = 'Common CSV';
export const INTEGRATION_SYSTEM_TYPE_WIX = 'Wix';
export const INTEGRATION_SYSTEM_TYPE_CDON = 'CDON';

export const integrationSystemType = {
	COMMON_CSV: {
		name: INTEGRATION_SYSTEM_TYPE_COMMON_CSV,
		num: 1,
	},
	WIX: {
		name: INTEGRATION_SYSTEM_TYPE_WIX,
		num: 2,
	},
	CDON: {
		name: INTEGRATION_SYSTEM_TYPE_COMMON_CSV,
		num: 0,
	},
};
export const integrationSystemTypeArray = [
	INTEGRATION_SYSTEM_TYPE_CDON,
	INTEGRATION_SYSTEM_TYPE_COMMON_CSV,
	INTEGRATION_SYSTEM_TYPE_WIX,
];
