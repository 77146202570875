/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useState } from 'react';
import {
	Container,
	Fab,
	Pagination,
	Paper,
	Stack,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getOrderBreakdownsIsLoad, getOrderBreakdownsList } from 'redux/reducers/orders/selectors';
import {
	getOrderBreakdownsRequest,
	putOrderBreakdownUpdateRequest,
} from 'redux/reducers/orders/reducer';
import { Loader } from 'ui/Loader';
import { StyledTableCell, StyledTableCellHover, StyledTableRow } from 'ui/StyledTable';
import { nanoid } from '@reduxjs/toolkit';
import { TOrderBreakdownsRequestPayload } from 'redux/reducers/orders/types';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { EditCellInput } from 'ui/EditCellInput';

export const Order: FC = () => {
	const params = useParams<{ clientId: string; orderId: string }>();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { search } = useLocation();

	const orderBreakdowns = useSelector(getOrderBreakdownsList);
	const orderBreakdownsIsLoad = useSelector(getOrderBreakdownsIsLoad);
	const { clientId, orderId } = params;
	const { pageNumber, fromPage } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState(Number(pageNumber) || 1);

	const [rowIndex, setRowIndex] = useState(0);
	const [cellIndex, setCellIndex] = useState(0);
	const [cellValue, setCellValue] = useState<string | number | boolean>('');
	const [editCell, setEditCell] = useState(false);

	// useEffect(() => {
	// 	dispatch(getOrderBreakdownsRequest({ orderId: Number(orderId), pageSize: 10 }));
	// }, [dispatch, orderId, params]);

	useEffect(() => {
		const dispatchParams: TOrderBreakdownsRequestPayload = {
			pageNumber: +(pageNumber ?? 1),
			pageSize: 10,
			orderId: Number(orderId),
		};
		dispatch(getOrderBreakdownsRequest(dispatchParams));
	}, [dispatch]);

	const handlePageChange = (event: unknown, page: number) => {
		setCurrentPage(page);
		const paramsHandle: TOrderBreakdownsRequestPayload = {
			pageNumber: page || 1,
			pageSize: 10,
			orderId: Number(orderId),
		};
		navigate({
			pathname: location.pathname,
			search: `?fromPage=${String(fromPage ?? 1)}pageNumber=${String(page)}`,
		});

		dispatch(getOrderBreakdownsRequest(paramsHandle));
	};

	const handleEditCell = (i: string, index: number, iIndex: number) => {
		setCellValue(i);
		setRowIndex(index);
		setCellIndex(iIndex);
		setEditCell(true);
	};

	const handleChangeCellValue = (value: string) => {
		setCellValue(value);
	};

	const handleCancelChangeCellValue = () => {
		setEditCell(false);
		setCellValue('');
	};
	const handleDispatchChangeCellValue = () => {
		setEditCell(false);
		setCellValue('');
		dispatch(
			putOrderBreakdownUpdateRequest({
				orderId: String(orderId),
				orderBreakdownId: orderBreakdowns?.items[rowIndex][0] ?? '',
				pageNumber: currentPage,
				body: [
					{
						fieldId: orderBreakdowns?.headerItems[cellIndex].id ?? '',
						newFieldValue: String(cellValue),
					},
				],
			}),
		);
	};
	const handlerBack = () => {
		navigate(`/client/${String(clientId ?? 0)}?tab=3&pageNumber=${String(fromPage ?? 1)}`);
	};
	return (
		<Container maxWidth="xl">
			<Fab color="primary" aria-label="add" sx={{ mb: '20px' }} onClick={handlerBack}>
				<ArrowBackIcon />
			</Fab>
			{!orderBreakdowns?.items?.length &&
				!orderBreakdowns?.headerItems?.length &&
				!orderBreakdownsIsLoad && (
					<div className="empty-data">
						<p className="empty__text">No orders found...</p>
					</div>
				)}
			{orderBreakdownsIsLoad && <Loader />}
			{!!orderBreakdowns?.items?.length &&
				!!orderBreakdowns?.headerItems?.length &&
				!orderBreakdownsIsLoad && (
					<>
						<Paper sx={{ overflowX: 'scroll' }}>
							<TableContainer>
								<Table stickyHeader aria-label="sticky table">
									<TableHead>
										<TableRow>
											{orderBreakdowns?.headerItems?.map(
												(headerItem, index) =>
													headerItem.isUserVisible && (
														<StyledTableCell
															sx={
																headerItem.name.length > 15
																	? { minWidth: '130px' }
																	: { minWidth: 'auto' }
															}
															align="center"
															colSpan={index}
															key={nanoid()}
															hidden={!headerItem.isUserVisible}
														>
															{headerItem.name}
														</StyledTableCell>
													),
											)}
										</TableRow>
									</TableHead>
									<TableBody>
										{orderBreakdowns?.items?.map((item, index) => (
											<StyledTableRow
												key={`row${index}`}
												className={editCell && rowIndex === index ? 'edit' : ''}
											>
												{item?.map(
													(i, iIndex) =>
														orderBreakdowns?.headerItems[iIndex].isUserVisible && (
															<StyledTableCellHover
																sx={
																	i.length > 20
																		? { minWidth: '300px', textAlign: 'left' }
																		: { minWidth: 'auto' }
																}
																align="center"
																colSpan={iIndex}
																key={`cell${index}-${iIndex}`}
																onClick={() => handleEditCell(i, index, iIndex)}
																className={editCell && rowIndex === index ? 'edit' : ''}
															>
																{editCell && rowIndex === index && cellIndex === iIndex ? (
																	<EditCellInput
																		placeholder={orderBreakdowns?.headerItems[iIndex].name}
																		value={cellValue}
																		changeValue={handleChangeCellValue}
																		cancel={handleCancelChangeCellValue}
																		check={handleDispatchChangeCellValue}
																	/>
																) : (
																	i
																)}
															</StyledTableCellHover>
														),
												)}
											</StyledTableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Paper>
						<Stack spacing={2} marginTop={2}>
							<Pagination
								count={Math.ceil((orderBreakdowns.itemsCount ?? 0) / 10)}
								page={currentPage}
								onChange={handlePageChange}
								shape="rounded"
								color="primary"
								sx={{ mb: '20px' }}
							/>
						</Stack>
					</>
				)}
		</Container>
	);
};
