/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FC, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from '@mui/material';
import { postFilesRequest } from 'redux/reducers/files/reducer';
import { getFilesSeparatorsList } from 'redux/reducers/files/selectors';
import useFileUpload from 'react-use-file-upload';
import { TUploadFileDialogProps } from './types';

export const UploadFileDialog: FC<TUploadFileDialogProps> = ({
	clientId,
	open,
	setClose,
	openMapping,
}) => {
	const dispatch = useDispatch();
	const [file, setFile] = useState<any>(null);
	const separatorsList = useSelector(getFilesSeparatorsList);

	const [separatorType, setSeparatorType] = useState('');
	const [headerRow, setHeaderRow] = useState(false);
	const {
		files,
		fileNames,
		fileTypes,
		totalSize,
		totalSizeInBytes,
		handleDragDropEvent,
		clearAllFiles,
		createFormData,
		setFiles,
		removeFile,
	} = useFileUpload();
	const inputRef = useRef();

	const handleCloseUploadFileDialog = () => {
		setClose();
		setFile(null);
		setSeparatorType('');
		setHeaderRow(false);
	};

	const handleSeparatorTypeChange = (event: SelectChangeEvent) => {
		setSeparatorType(event.target.value);
	};

	const handleUploadFileClient = () => {
		handleCloseUploadFileDialog();
		dispatch(
			postFilesRequest({
				File: file,
				ClientId: clientId,
				SeparatorType: Number(separatorType),
				IsHeaderRow: headerRow,
			}),
		);

		openMapping();
	};

	const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFile(event.target.files?.[0] ?? null);
		// console.log('handleChangeFile', event?.target?.files?.[0].type);

		// const reader = new FileReader();
		// reader.readAsText(event.target.files?.[0] as Blob);
		// reader.onload = () => setResult(String(reader.result));
	};

	return (
		<div style={{ minWidth: '900px' }}>
			<Dialog
				open={open}
				onClose={handleCloseUploadFileDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title" sx={{ width: '400px' }}>
					Upload File
				</DialogTitle>
				<DialogContent sx={{ width: '260px' }}>
					<FormGroup>
						{/* <FormControlLabel
							control={
								<Checkbox checked={headerRow} onChange={(e) => setHeaderRow(e.target.checked)} />
							}
							label="Header row"
						/> */}
						{/* <FormControl fullWidth sx={{ marginTop: '10px' }}>
							<InputLabel id="demo-simple-select-label">Separator Type</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={separatorType}
								label="Separator type"
								onChange={handleSeparatorTypeChange}
							>
								{separatorsList?.map((item) => (
									<MenuItem key={item.name} value={item.id}>
										{item.name}
									</MenuItem>
								))}
							</Select>
						</FormControl> */}
						{/* <div
							// css={DropzoneCSS}
							onDragEnter={(e) => handleDragDropEvent(e as unknown as Event)}
							onDragOver={(e) => handleDragDropEvent(e as unknown as Event)}
							onDrop={(e) => {
								handleDragDropEvent(e as unknown as Event);
								setFiles(e as unknown as Event);
							}}
						>
							<p>Drag and drop files here</p>

							<button onClick={() => inputRef?.current?.click()}>Or select files to upload</button>

							<input
								ref={inputRef}
								type="file"
								multiple
								style={{ display: 'none' }}
								onChange={(e) => {
									setFiles(e, 'a');
									inputRef.current.value = null;
								}}
							/>
						</div> */}
						<Button variant="contained" component="label" style={{ marginTop: '20px' }}>
							Select File
							<input type="file" hidden onChange={handleChangeFile} />
						</Button>
					</FormGroup>
				</DialogContent>
				<div style={{ padding: '5px 20px' }}>{file?.name}</div>

				<DialogActions>
					<Button onClick={handleCloseUploadFileDialog}>Cancel</Button>
					<Button onClick={handleUploadFileClient} autoFocus disabled={!file}>
						Upload File
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
