import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField } from '@mui/material';
import { postMappingPresetsRequest } from 'redux/reducers/files/reducer';
import { TSaveMappingDialogProps } from './types';

export type TInput = {
	id: string;
	name: string;
	iIndex: number;
};

export const SaveMappingDialog: FC<TSaveMappingDialogProps> = ({
	clientId,
	open,
	setClose,
	setSaveAndClose,
	json,
}) => {
	const dispatch = useDispatch();
	const [name, setName] = useState('');

	const handleFileMapping = () => {
		dispatch(postMappingPresetsRequest({ clientId, body: { name, json } }));
		setSaveAndClose();
	};

	return (
		<Dialog
			open={open}
			onClose={setClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">Save Mapping</DialogTitle>
			<DialogContent>
				<TextField
					id="outlined-basic"
					label="Name of Preset"
					variant="outlined"
					sx={{ marginTop: '5px' }}
					onChange={(e) => setName(e.target.value)}
				/>
			</DialogContent>

			<DialogActions>
				<Button onClick={setClose}>Cancel</Button>
				<Button onClick={setSaveAndClose} autoFocus>
					Save only Mapping
				</Button>
				<Button onClick={handleFileMapping} autoFocus disabled={!name}>
					Save Mapping & Preset
				</Button>
			</DialogActions>
		</Dialog>
	);
};
