/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
	styled,
	TableCell,
	tableCellClasses,
	TableRow,
	tableRowClasses,
	Tooltip,
	tooltipClasses,
	TooltipProps,
} from '@mui/material';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#016193',
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.head}.filter`]: {
		fontWeight: 'bolder',
		// fontStyle: 'italic',
		color: '#95be47',
	},

	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));
export const StyledTableRow = styled(TableRow)(() => ({
	'&:nth-of-type(odd)': {
		backgroundColor: '#01619308',
		border: 0,
	},
	'&:last-child td, &:last-child th': {
		border: 0,
	},
	[`&.${tableCellClasses.body}.edit`]: {
		backgroundColor: '#72923552',
	},
	'&:hover': {
		backgroundColor: '#cecece55',
	},
	[`&.${tableRowClasses.root}.edit`]: {
		backgroundColor: '#93939352',
	},
}));
export const StyledTableCellHover = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#016193',
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
	[`&.${tableCellClasses.body}.error`]: {
		backgroundColor: '#ff007e1c',
	},
	[`&.${tableCellClasses.body}.created`]: {
		backgroundColor: '#0161931f',
	},
	[`&.${tableCellClasses.body}.process`]: {
		backgroundColor: '#e1be0f3d',
	},
	[`&.${tableCellClasses.body}.completed`]: {
		backgroundColor: '#7ea13a29',
	},
	// [`&.${tableCellClasses.body}.edit`]: {
	// 	backgroundColor: '#72923552',
	// },

	[`&.${tableCellClasses.body}:hover`]: {
		backgroundColor: '#9a9b9863',
	},
}));

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(14),
		border: '1px solid #dadde9',
		top: '25px',
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.common.white,
	},
}));
