/* eslint-disable react-hooks/rules-of-hooks */
import { Container } from '@mui/material';
import { FC } from 'react';
import { VATReportDownload } from './VATReportDownload';
import { VATReportDownloadWithPayment } from './VATReportDownloadWithPayment';

export const VATReportDownloadContainer: FC = () => {
	return (
		<div>
			<Container maxWidth="lg" sx={{ marginTop: '40px' }}>
				<VATReportDownload />
				{/* <VATReportDownloadWithPayment /> */}
			</Container>
		</div>
	);
};
