import { LoginForm } from 'components/LoginForm';
import { Page } from 'layouts/Page';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getIsAuth } from 'redux/reducers/auth/selectors';

export const LoginPage: FC = () => {
	const navigate = useNavigate();

	const ifAuth = useSelector(getIsAuth);

	useEffect(() => {
		if (ifAuth) navigate('/');
	});

	return <LoginForm />;
};
