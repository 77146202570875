import { Order } from 'components/Order';
import { Page } from 'layouts/Page';
import { LoginPage } from 'pages/LoginPage';
import { FC } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { getIsAuth } from 'redux/reducers/auth/selectors';

export const OrderPage: FC = () => {
	const ifAuth = useSelector(getIsAuth);

	return ifAuth ? (
		<Page title="Home">
			<Order />
		</Page>
	) : (
		<LoginPage />
	);

	// return (
	// 	<Page title="Order">
	// 		<Order />
	// 	</Page>
	// );
};
