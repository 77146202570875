/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
	newVATReportRequest,
	newVATReportWithPaymentRequest,
	vatReportFileRequest,
	vatReportStatusRequest,
} from 'redux/reducers/vatreport/reducer';
import {
	newVatReport,
	vatReportStatus,
	vatReportStatusIsLoad,
} from 'redux/reducers/vatreport/selectors';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { LinearProgressWithLabel } from '../LinearProgressWithLabel';

export const VATReportDownload: FC = () => {
	const { taskId } = useParams<{ taskId: string }>();
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const newVatReportTaskId = useSelector(newVatReport);
	const downloadStatus = useSelector(vatReportStatus);

	const downloadStatusIsLoad = useSelector(vatReportStatusIsLoad);

	const [startDownload, setStartDownload] = useState(!(taskId === 'new'));
	const [date, setDate] = useState<string | null>(null);
	const [endDownload, setEndDownload] = useState(false);

	const downloadHandler = () => {
		setEndDownload(false);
		setDate(null);
		dispatch(newVATReportRequest());
		setStartDownload(true);
	};

	const downloadHandlerWithPayment = () => {
		setEndDownload(false);
		setDate(null);
		dispatch(newVATReportWithPaymentRequest());
		setStartDownload(true);
	};

	useEffect(() => {
		let interval: ReturnType<typeof setInterval>;
		if (startDownload && taskId) {
			interval = setInterval(
				() => {
					dispatch(vatReportStatusRequest({ taskId }));
				},
				startDownload ? 1000 : 0,
			);
		}
		return () => clearInterval(interval);
	}, [dispatch, startDownload, taskId]);

	if (downloadStatus?.status === 'finished' && startDownload && taskId) {
		setStartDownload(false);
		dispatch(vatReportFileRequest({ taskId }));
		setEndDownload(true);
	}
	useEffect(() => {
		if (downloadStatusIsLoad) {
			setDate(downloadStatus?.timestamp ?? '');
		}
	}, [downloadStatus?.timestamp, downloadStatusIsLoad]);

	useEffect(() => {
		if (newVatReportTaskId?.taskId) {
			navigate(`/vat-report/${newVatReportTaskId.taskId}`);
		}
	}, [navigate, newVatReportTaskId?.taskId]);

	return (
		<div style={{ marginBottom: '20px' }}>
			<p>
				<Button variant="contained" onClick={downloadHandler} disabled={startDownload}>
					Download IOSS VAT Reports
				</Button>
			</p>
			<p>
				<Button variant="contained" onClick={downloadHandlerWithPayment} disabled={startDownload}>
					Download IOSS VAT Reports with payment issues
				</Button>
			</p>

			{startDownload && (
				<Box sx={{ width: '100%', marginTop: '40px' }}>
					<LinearProgressWithLabel value={downloadStatus?.progress ?? 0} />
				</Box>
			)}
			{date && (
				<p style={{ color: '#016193', fontWeight: 600, marginTop: '40px' }}>
					Fiscal report date : {date.slice(0, 10)}
				</p>
			)}

			{endDownload && (
				<p style={{ color: '#016193', fontWeight: 600 }}>File downloaded successfully!</p>
			)}
		</div>
	);
};
