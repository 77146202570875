/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Button, Container, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
	newVATReportRequest,
	vatReportFileRequest,
	vatReportStatusRequest,
} from 'redux/reducers/vatreport/reducer';
import {
	newVatReport,
	newVatReportIsLoad,
	vatReportStatus,
	vatReportStatusIsLoad,
} from 'redux/reducers/vatreport/selectors';
import useInterval from 'use-interval';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { LinearProgressWithLabel } from './LinearProgressWithLabel';
import { VATReportDownload } from './VATReportDownload';

export const VATReport: FC = () => {
	const navigate = useNavigate();

	let timerId;
	const dispatch = useDispatch();
	const newVatReportTaskId = useSelector(newVatReport);
	const newVatReportTaskIdIsLoad = useSelector(newVatReportIsLoad);
	const downloadStatus = useSelector(vatReportStatus);
	const downloadStatusIsLoad = useSelector(vatReportStatusIsLoad);

	const [startDownload, setStartDownload] = useState(false);
	const [date, setDate] = useState<string | null>(null);
	const [endDownload, setEndDownload] = useState(false);

	const downloadHandler = () => {
		setEndDownload(false);
		setDate(null);
		dispatch(newVATReportRequest());
		setStartDownload(true);
	};

	// useEffect(() => {
	// 	let interval: ReturnType<typeof setInterval>;
	// 	if (startDownload && newVatReportTaskId?.taskId) {
	// 		interval = setInterval(
	// 			() => {
	// 				dispatch(vatReportStatusRequest({ taskId: newVatReportTaskId.taskId }));
	// 			},
	// 			startDownload ? 1000 : 0,
	// 		);
	// 	}
	// 	return () => clearInterval(interval);
	// }, [dispatch, newVatReportTaskId, startDownload]);

	// if (downloadStatus?.status === 'finished' && startDownload && newVatReportTaskId?.taskId) {
	// 	setStartDownload(false);
	// 	dispatch(vatReportFileRequest({ taskId: newVatReportTaskId.taskId }));
	// 	setEndDownload(true);
	// }
	// useEffect(() => {
	// 	if (downloadStatusIsLoad) {
	// 		setDate(downloadStatus?.timestamp ?? '');
	// 	}
	// }, [downloadStatus?.timestamp, downloadStatusIsLoad]);

	useEffect(() => {
		console.log('useEffect');
		if (newVatReportTaskId?.taskId) {
			navigate(`/vat-report/${newVatReportTaskId.taskId}`);
		}
	}, [navigate, newVatReportTaskId?.taskId]);

	return (
		<div>
			<Container maxWidth="lg" sx={{ marginTop: '40px' }}>
				<VATReportDownload />
			</Container>
		</div>
	);
};
