import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { FC } from 'react';
import { Autocomplete, Box, DialogContent, FormControl, TextField } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { TCreateMonthlyFeeChangeRequestDialogProps } from './types';

const emList = [
	{ label: 'Google', value: 'EM1' },
	{ label: 'Apple', value: 'EM2' },
	{ label: 'Microsoft', value: 'EM3' },
	{ label: 'IBM', value: 'EM4' },
	{ label: 'Oracle', value: 'EM5' },
	{ label: 'Amazon', value: 'EM6' },
	{ label: 'Facebook', value: 'EM7' },
	{ label: 'Alibaba', value: 'EM8' },
	{ label: 'Tencent', value: 'EM9' },
	{ label: 'Netflix', value: 'EM10' },
];

const monthlyFeeList = [
	{ label: '100', value: '100' },
	{ label: '80', value: '80' },
	{ label: '60', value: '60' },
	{ label: '40', value: '40' },
	{ label: '20', value: '20' },
];

const easFeeList = [
	{ label: '23', value: 'A' },
	{ label: '20', value: 'B' },
	{ label: '18.01', value: 'C' },
	{ label: '16', value: 'D' },
	{ label: '14.23', value: 'E' },
];

export const CreateMonthlyFeeChangeRequestDialog: FC<TCreateMonthlyFeeChangeRequestDialogProps> = ({
	open,
	setClose,
}) => {
	const handler = () => {
		console.log('handler');
		setClose();
	};

	return (
		<Dialog
			open={open}
			onClose={setClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			fullWidth
		>
			<DialogTitle id="alert-dialog-title">Create Monthly Fee Change Request</DialogTitle>
			<DialogContent>
				<Box display="flex" justifyContent="center">
					<FormControl sx={{ minWidth: 120, width: '100%' }}>
						<Autocomplete
							disablePortal
							id="em-input"
							options={emList}
							sx={{ width: '100%', mt: 2 }}
							renderInput={(params) => <TextField {...params} label="EM" />}
						/>
						{/* <TextField
							id="monthly-fee-input"
							label="Monthly fee"
							variant="outlined"
							type="string"
							sx={{ mt: 2 }}
						/> */}
						<Autocomplete
							disablePortal
							id="monthly-fee-input"
							options={monthlyFeeList}
							sx={{ width: '100%', mt: 2 }}
							renderInput={(params) => <TextField {...params} label="EMonthly fee" />}
						/>

						{/* <TextField
							id="eas-fee-input"
							label="EAS fee"
							variant="outlined"
							type="string"
							sx={{ mt: 2 }}
						/> */}
						<Autocomplete
							disablePortal
							id="eas-fee-input"
							options={easFeeList}
							sx={{ width: '100%', mt: 2 }}
							renderInput={(params) => <TextField {...params} label="EAS fee" />}
						/>

						{/* <TextField
							id="requested-at-input"
							label="Requested at"
							variant="outlined"
							type="string"
							sx={{ mt: 2 }}
						/> */}

						<LocalizationProvider dateAdapter={AdapterLuxon}>
							<MobileDatePicker label="Requested at" defaultValue={DateTime.now()} sx={{ mt: 2 }} />
						</LocalizationProvider>
					</FormControl>
				</Box>
			</DialogContent>

			<DialogActions>
				<Button onClick={setClose}>Cancel</Button>
				<Button onClick={handler} autoFocus>
					Create Monthly Fee Change Request
				</Button>
			</DialogActions>
		</Dialog>
	);
};
