import {
	Button,
	Container,
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { useState } from 'react';
import { Loader } from 'ui/Loader';
import { StyledTableCell, StyledTableRow } from 'ui/StyledTable';
import { CreateMonthlyFeeChangeRequestDialog } from './CreateMonthlyFeeChangeRequestDialog';

export const MonthlyFee = () => {
	const data = [
		{
			id: 101,
			emFullName: 'Google',
			emId: 'WD-00092102',
			currentMonthlyFee: 100,
			newMonthlyFee: 100,
			newEASFeeClass:
				'Normal Pricing 2023 | Union: 0.32:0%, Non Union: 0:8.8%, Import:1.25:0%, B2C Domestic: 0:0%FC Rep: 0:0%',
			requestAt: '2021-10-01T12:00:00',
			createBy: '2021-10-01T07:00:00',
			effectiveMonth: '2021-10',
			status: 'Pending',
		},
		{
			id: 102,
			emFullName: 'Apple',
			emId: 'SS-00092131',
			currentMonthlyFee: 80,
			newMonthlyFee: 86,
			newEASFeeClass:
				'Normal Pricing 2023 | Union: 0.32:0%, Non Union: 0:8.8%, Import:1.25:0%, B2C Domestic: 0:0%FC Rep: 0:0%',
			requestAt: '2021-10-07T12:00:00',
			createBy: '2021-10-07T07:00:00',
			effectiveMonth: '2021-10',
			status: 'Pending',
		},
		{
			id: 3,
			emFullName: 'Microsoft',
			emId: 'LM-00092149',
			currentMonthlyFee: 80,
			newMonthlyFee: 86,
			newEASFeeClass:
				'Monthly Basic | Union: 0.16:0%, Non Union: 0:6.8%, Import:1:0%, B2C Domestic: 0:0%FC Rep: 0:0%',
			requestAt: '2021-10-09T12:00:00',
			createBy: '2021-10-09T07:00:00',
			effectiveMonth: '2021-10',
			status: 'Pending',
		},
		{
			id: 4,
			emFullName: 'IBM',
			emId: 'XC-00092152',
			currentMonthlyFee: 80,
			newMonthlyFee: 86,
			newEASFeeClass:
				'Monthly Starter | Union: 0.24:0%, Non Union: 0:6.8%, Import:1.5:0%, B2C Domestic: 0:0%FC Rep: 0:0%',
			requestAt: '2021-10-09T12:00:00',
			createBy: '2021-10-09T07:00:00',
			effectiveMonth: '2021-10',
			status: 'Canceled',
		},
	];
	const dataIsLoad = false;

	const [openCreateMonthlyFeeChangeRequestDialog, setOpenCreateMonthlyFeeChangeRequestDialog] =
		useState(false);

	const handleCreateMonthlyFeeChangeRequestDialog = () => {
		console.log('createMonthlyFeeChangeRequestDialog');
		setOpenCreateMonthlyFeeChangeRequestDialog(true);
	};

	const handleCloseCreateMonthlyFeeChangeRequestDialog = () => {
		setOpenCreateMonthlyFeeChangeRequestDialog(false);
	};

	return (
		<div style={{ margin: '0 15px' }}>
			{!data.length && !dataIsLoad && (
				<div className="empty-data">
					<p className="empty__text">No clients found...</p>
				</div>
			)}
			{!!dataIsLoad && <Loader />}
			{data.length && !dataIsLoad && (
				<>
					<Button
						variant="contained"
						onClick={handleCreateMonthlyFeeChangeRequestDialog}
						sx={{ mb: 2 }}
					>
						Create Monthly Fee Change Request
					</Button>
					<Paper>
						<TableContainer sx={{ maxHeight: 800 }}>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										<StyledTableCell align="center" colSpan={2}>
											EM Full Name
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={2}>
											EM ID
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={2}>
											Current Monthly Fee
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={2}>
											New Monthly Fee
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={2}>
											New EAS Fee Class
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={2}>
											Requested At
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={2}>
											Created By
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={2}>
											Effective Month
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={2}>
											Status
										</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.map((item, index) => (
										<StyledTableRow hover key={item.id}>
											<StyledTableCell align="center" colSpan={2}>
												{item.emFullName}
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={2}>
												{item.emId}
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={2}>
												{item.currentMonthlyFee}
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={2}>
												{item.newMonthlyFee}
											</StyledTableCell>
											<StyledTableCell align="left" colSpan={2}>
												{item.newEASFeeClass}
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={2}>
												{item.requestAt}
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={2}>
												{item.createBy}
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={2}>
												{item.effectiveMonth}
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={2}>
												{item.status}
											</StyledTableCell>
										</StyledTableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
					{/* {data.length > 10 && (
                <Stack spacing={2} marginTop={2}>
                  <Pagination
                    count={Math.ceil((clients.clients?.itemsCount ?? 0) / 10)}
                    page={currentPage}
                    onChange={handlePageChange}
                    // variant="outlined"
                    shape="rounded"
                    color="primary"
                  />
                </Stack>
              )}
              <DeleteDialog
                open={openDeleteDialog}
                setClose={handleCloseDeleteDialog}
                handleDelete={handleDeleteClient}
                text="Are you sure you want to delete this client?"
              />
            </>
          )} */}
					<CreateMonthlyFeeChangeRequestDialog
						open={openCreateMonthlyFeeChangeRequestDialog}
						setClose={handleCloseCreateMonthlyFeeChangeRequestDialog}
					/>
				</>
			)}
		</div>
	);
};
