import Header from 'layouts/Header';
import { FC } from 'react';
import { useSelector } from 'react-redux';
// import Footer from 'layouts/Footer';
// import { Header } from 'layouts/Header';
// import { getTheme } from 'redux/reducers/settings/selectors';
// import { SettingsModal } from 'layouts/Modals/SettingsModal';
import { TPageProps } from './types';

// ==========================================:
export const Page: FC<TPageProps> = ({ children, title }) => {
	const pageTitle = title ? `| ${title}` : '';

	// const theme = useSelector(getTheme);

	return (
		<div>
			<Header />
			<main className="main">
				<div className="main-content">{children}</div>
			</main>
			{/* <Footer /> */}
			{/* <SettingsModal /> */}
		</div>
	);
};
