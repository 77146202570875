import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { FC } from 'react';

export type TCircularProgressWithLabelProps = {
	value: number;
};

export const CircularProgressWithLabel: FC<TCircularProgressWithLabelProps> = ({ value }) => {
	return (
		<Box sx={{ position: 'relative', display: 'inline-flex' }}>
			<CircularProgress variant="determinate" value={value} size={30} />
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Typography
					variant="caption"
					component="div"
					color="text.secondary"
					sx={{ fontSize: '0.5rem;' }}
				>{`${Math.round(value)}%`}</Typography>
			</Box>
		</Box>
	);
};
