import { Page } from 'layouts/Page';
import { FC } from 'react';
import { Home } from 'components/Home';
import { useSelector } from 'react-redux';
import { getIsAuth } from 'redux/reducers/auth/selectors';
import { LoginPage } from 'pages/LoginPage';

export const HomePage: FC = () => {
	const ifAuth = useSelector(getIsAuth);

	return ifAuth ? (
		<Page title="Home">
			<Home />
		</Page>
	) : (
		<LoginPage />
	);
};
