/* eslint-disable no-nested-ternary */
import {
	Button,
	Chip,
	Container,
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { FC } from 'react';
import { StyledTableCell, StyledTableCellHover, StyledTableRow } from 'ui/StyledTable';
import swedBankIntegrationArray from './swed-bank-integration.json';
import {
	ISwedBankIntegrationItem,
	PROCESSED_STATUS,
	TO_BE_MANUALLY_PROCESSED_STATUS,
} from './types';

export const SwedBankIntegration: FC = () => {
	const payment = swedBankIntegrationArray as unknown as ISwedBankIntegrationItem;
	return (
		<div style={{ margin: '0 15px' }}>
			<h1 style={{ color: '#016193' }}>Swed Bank Integration</h1>
			<Paper>
				<TableContainer>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								<StyledTableCell align="center" colSpan={2}>
									Doc #
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={2}>
									Payment date
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={2}>
									Client IBAN
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={2}>
									Client name
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={2}>
									Bank
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={2}>
									Amount
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={2}>
									Processed date
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={2}>
									Payment description
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={2}>
									Reg. #
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={2}>
									EAS Invoice #
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={2}>
									Status
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={2} />
							</TableRow>
						</TableHead>
						<TableBody>
							{swedBankIntegrationArray?.map((item) => (
								<StyledTableRow hover key={item.docNum}>
									<StyledTableCellHover
										align="center"
										colSpan={2}
										className={`${
											item?.status === PROCESSED_STATUS
												? 'completed'
												: item?.status === TO_BE_MANUALLY_PROCESSED_STATUS
												? 'process'
												: ''
										}`}
									>
										{item.docNum}
									</StyledTableCellHover>
									<StyledTableCellHover
										align="center"
										colSpan={2}
										className={`${
											item?.status === PROCESSED_STATUS
												? 'completed'
												: item?.status === TO_BE_MANUALLY_PROCESSED_STATUS
												? 'process'
												: ''
										}`}
									>
										{item.paymentDate}
									</StyledTableCellHover>
									<StyledTableCellHover
										align="center"
										colSpan={2}
										className={`${
											item?.status === PROCESSED_STATUS
												? 'completed'
												: item?.status === TO_BE_MANUALLY_PROCESSED_STATUS
												? 'process'
												: ''
										}`}
									>
										{item.clientIBAN}
									</StyledTableCellHover>
									<StyledTableCellHover
										align="center"
										colSpan={2}
										className={`${
											item?.status === PROCESSED_STATUS
												? 'completed'
												: item?.status === TO_BE_MANUALLY_PROCESSED_STATUS
												? 'process'
												: ''
										}`}
									>
										{item.clientName}
									</StyledTableCellHover>
									<StyledTableCellHover
										align="center"
										colSpan={2}
										className={`${
											item?.status === PROCESSED_STATUS
												? 'completed'
												: item?.status === TO_BE_MANUALLY_PROCESSED_STATUS
												? 'process'
												: ''
										}`}
									>
										{item.bank}
									</StyledTableCellHover>
									<StyledTableCellHover
										align="center"
										colSpan={2}
										className={`${
											item?.status === PROCESSED_STATUS
												? 'completed'
												: item?.status === TO_BE_MANUALLY_PROCESSED_STATUS
												? 'process'
												: ''
										}`}
									>
										{item.amount}
									</StyledTableCellHover>
									<StyledTableCellHover
										align="center"
										colSpan={2}
										className={`${
											item?.status === PROCESSED_STATUS
												? 'completed'
												: item?.status === TO_BE_MANUALLY_PROCESSED_STATUS
												? 'process'
												: ''
										}`}
									>
										{item.processedDate}
									</StyledTableCellHover>
									<StyledTableCellHover
										align="center"
										colSpan={2}
										className={`${
											item?.status === PROCESSED_STATUS
												? 'completed'
												: item?.status === TO_BE_MANUALLY_PROCESSED_STATUS
												? 'process'
												: ''
										}`}
									>
										{item.paymentDescription}
									</StyledTableCellHover>
									<StyledTableCellHover
										align="center"
										colSpan={2}
										className={`${
											item?.status === PROCESSED_STATUS
												? 'completed'
												: item?.status === TO_BE_MANUALLY_PROCESSED_STATUS
												? 'process'
												: ''
										}`}
									>
										{item.regNum}
									</StyledTableCellHover>
									<StyledTableCellHover
										align="center"
										colSpan={2}
										className={`${
											item?.status === PROCESSED_STATUS
												? 'completed'
												: item?.status === TO_BE_MANUALLY_PROCESSED_STATUS
												? 'process'
												: ''
										}`}
									>
										{item.EASInvoiceNum}
									</StyledTableCellHover>
									<StyledTableCellHover
										align="center"
										colSpan={2}
										style={{
											color:
												item.status === PROCESSED_STATUS
													? 'green'
													: item.status === TO_BE_MANUALLY_PROCESSED_STATUS
													? '#d6a300'
													: '',
										}}
										className={`${
											item?.status === PROCESSED_STATUS
												? 'completed'
												: item?.status === TO_BE_MANUALLY_PROCESSED_STATUS
												? 'process'
												: ''
										}`}
									>
										<Chip
											label={item.status}
											sx={{
												bgcolor: `${
													item.status === PROCESSED_STATUS
														? '#7ea13a'
														: item.status === TO_BE_MANUALLY_PROCESSED_STATUS
														? '#e1be0d'
														: '#016193'
												}`,
												color: 'white',
											}}
											size="small"
										/>
									</StyledTableCellHover>
									<StyledTableCellHover
										align="center"
										colSpan={2}
										className={`${
											item?.status === PROCESSED_STATUS
												? 'completed'
												: item?.status === TO_BE_MANUALLY_PROCESSED_STATUS
												? 'process'
												: ''
										}`}
									>
										<Button>Mark as processed</Button>
									</StyledTableCellHover>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</div>
	);
};
