/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import {
	Chip,
	Pagination,
	Paper,
	Stack,
	Table,
	TableBody,
	TableContainer,
	TableHead,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { fileStatus, fileStatusArray } from 'redux/reducers/clients/constants';
import { getClientFilesRequest } from 'redux/reducers/clients/reducer';
import { getClientFiles, getClientFilesIsLoad } from 'redux/reducers/clients/selectors';
import { TClientFilesRequestPayload } from 'redux/reducers/clients/types';
import { deleteFilesRequest, filesExampleRequest } from 'redux/reducers/files/reducer';
import { Loader } from 'ui/Loader';
import { StyledTableCell, StyledTableRow } from 'ui/StyledTable';
import queryString from 'query-string';
import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteDialog } from 'ui/DeleteDialog';
import { CircularProgressWithLabel } from './CircularProgressWithLabel';
import { TClientFilesListProps } from './types';

export const ClientFilesList: FC<TClientFilesListProps> = ({ openMapping, setFileId }) => {
	const params = useParams<{ clientId: string }>();
	const navigate = useNavigate();
	const location = useLocation();
	const { search } = useLocation();
	const { clientId } = params;

	const { pageNumber } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState(Number(pageNumber) || 1);

	const dispatch = useDispatch();
	const files = useSelector(getClientFiles);
	const filesIsLoad = useSelector(getClientFilesIsLoad);

	useEffect(() => {
		const paramsHandle: TClientFilesRequestPayload = {
			pageNumber: +(pageNumber || 1),
			pageSize: 10,
			clientId: Number(clientId),
		};

		console.log('useEffect getClientFilesRequest');

		dispatch(getClientFilesRequest(paramsHandle));
	}, [dispatch]);

	const handlePageChange = (event: unknown, page: number) => {
		setCurrentPage(page);
		const paramsHandle: TClientFilesRequestPayload = {
			pageNumber: page || 1,
			pageSize: 10,
			clientId: Number(clientId),
		};
		navigate({
			pathname: location.pathname,
			search: `?tab=0&pageNumber=${String(page)}`,
		});

		dispatch(getClientFilesRequest(paramsHandle));
	};
	const handleSelectFile = (fileId: number) => {
		dispatch(filesExampleRequest({ fileId, separatorType: 0 }));
		setFileId(fileId);
		openMapping();
	};

	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false);
	};

	const [deleteFileId, setDeleteFileId] = useState(0);

	const handleOpenDeleteDialog = (fileId: number) => {
		setDeleteFileId(fileId);
		setOpenDeleteDialog(true);
	};
	const handleDeleteFile = () => {
		handleCloseDeleteDialog();
		dispatch(
			deleteFilesRequest({
				clientId: Number(clientId),
				fileId: deleteFileId,
			}),
		);
	};

	return (
		<>
			{!files?.items.length && !filesIsLoad && (
				<div className="empty-data">
					<p className="empty__text">No files found...</p>
				</div>
			)}

			{filesIsLoad && <Loader />}

			{!!files?.items.length && !filesIsLoad && (
				<>
					<Paper sx={{ mt: '-24px', ml: '-24px', mr: '-24px' }}>
						<TableContainer sx={{ maxHeight: 800 }}>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<StyledTableRow>
										<StyledTableCell align="left" colSpan={1}>
											File Id
										</StyledTableCell>
										<StyledTableCell align="left" colSpan={4}>
											Name
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={1}>
											Status
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={1} />
										<StyledTableCell align="center" colSpan={3}>
											Created at
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={1}>
											Action
										</StyledTableCell>
									</StyledTableRow>
								</TableHead>
								<TableBody>
									{files?.items?.map((item) => (
										<StyledTableRow
											hover
											key={item.id}
											onClick={() => {
												if (item.status === fileStatus.CREATED.num) handleSelectFile(item.id);
											}}
										>
											<StyledTableCell align="left" colSpan={1}>
												{item.id}
											</StyledTableCell>
											<StyledTableCell align="left" colSpan={4}>
												{item.fileName}
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={1}>
												<Chip
													label={fileStatusArray[item.status]}
													sx={{
														bgcolor: `${
															item.status === fileStatus.CREATED.num
																? '#016193'
																: item.status === fileStatus.FINISHED.num
																? '#7ea13a'
																: '#6ec1e4'
														}`,
														color: 'white',
													}}
													size="small"
												/>
											</StyledTableCell>

											<StyledTableCell align="left" colSpan={1}>
												{item.status === fileStatus.PROCESSING.num && (
													<CircularProgressWithLabel value={item.currentProgress} />
												)}
											</StyledTableCell>

											<StyledTableCell align="center" colSpan={3}>
												{new Date(item.createdAt).toLocaleDateString()}
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={1}>
												<DeleteIcon
													sx={{ color: '#016193', cursor: 'pointer' }}
													onClick={(e) => {
														e.stopPropagation();
														// setMappingItem(index);
														handleOpenDeleteDialog(item.id);
													}}
												/>
											</StyledTableCell>
										</StyledTableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>

					{Math.ceil((files?.itemsCount ?? 0) / 10) > 1 && (
						<Stack spacing={2} marginTop={2}>
							<Pagination
								count={Math.ceil((files?.itemsCount ?? 0) / 10)}
								page={currentPage}
								onChange={handlePageChange}
								shape="rounded"
								color="primary"
							/>
						</Stack>
					)}
				</>
			)}
			<DeleteDialog
				open={openDeleteDialog}
				setClose={handleCloseDeleteDialog}
				handleDelete={handleDeleteFile}
				text="Are you sure you want to delete this file?"
			/>
		</>
	);
};
